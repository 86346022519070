import { Component, OnInit, NgModule, Output, EventEmitter } from '@angular/core';
import {Router} from "@angular/router";
import { MatDialog } from '@angular/material';
import { LocalSaveService } from '../local-save.service';
import { ComponentPageTitle } from '../page-title/page-title';
import { ValidatemenusService } from '../validatemenus.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
 	title:string="Gestión";
  newtitle:string;
 	baropen:boolean=false;
  wheight:any;
  innerheight:any;
  typeuser;
  usuario;
  selected = 0;
  gestion: boolean = false;
  paises = [
    {nombre: 'Todos', img:'', codigo_pais:0, AREA:'', flag:''},
    {nombre: 'Guatemala', img:'./assets/guatemala.png', codigo_pais:1, flag:'./assets/guatemala1.png'},
    {nombre: 'El Salvador', img:'./assets/salvador.png', codigo_pais:2, flag:'./assets/salvador1.png'},
    {nombre: 'Nicaragua', img:'./assets/nicaragua.png', codigo_pais:5, flag:'./assets/nicaragua1.png'},
    {nombre: 'Costa Rica', img:'./assets/costarica.png', codigo_pais:3, flag:'./assets/costarica1.png'},
    {nombre: 'Panamá', img:'./assets/panama.png', codigo_pais:4, flag:'./assets/panama1.png'}
  ];

 	constructor(private validatemenu:ValidatemenusService,private router: Router,private localSave:LocalSaveService,public dialog: MatDialog, public componentPageTitle: ComponentPageTitle) {
     this.wheight= window.innerHeight+'px';
     this.innerheight= window.innerHeight*0.9+'px';
     this.localSave.set("country", this.selected);
  }

	ngOnInit() {
    this.componentPageTitle.title='';
  }

  fnvalidatemenu(menu){
    return this.validatemenu.permiso(menu);
  }

  getTitle() {
    return this.componentPageTitle.title;
  }

	openbar() {
		if(this.baropen){
			this.baropen = false;
		}else{
			this.baropen = true;
		}
	}

  cerrarsesion(){
    this.router.navigate(['login']);
  }


  gotomenuinicial():void{
    this.gestion = false;
    this.openbar();
    this.router.navigate(['home/menuinicial']);
  }

  gotobots():void{
    this.gestion = false;
    this.openbar();
    this.router.navigate(['home/bots']);
  }

  gotocomandos():void{
    this.gestion = false;
    this.openbar();
    this.router.navigate(['home/comandos']);
  }

  gotobroadcasts():void{
    this.gestion = false;
    this.openbar();
    this.router.navigate(['home/broadcasts']);
  }

  gotoperfiles():void{
    this.gestion = false;
    this.openbar();
    this.router.navigate(['home/perfiles']);
  }

  gotonodos():void{
    this.gestion = false;
    this.openbar();
    this.router.navigate(['home/nodos']);
  }

  gotofileexplorer():void{
    this.gestion = false;
    this.openbar();
    this.router.navigate(['home/file-explorer']);
  }

  gotosegmentoadmon():void{
    this.gestion = false;
    this.openbar();
    this.router.navigate(['home/segmento']);
  }

  gotoareaadmon():void{
    this.gestion = false;
    this.openbar();
    this.router.navigate(['home/area']);
  }

  gotoplanesrequisitos():void{
    this.openbar();
    this.router.navigate(['home/planes-requisitos']);
  }

  gotobackoffice():void{
    this.openbar();
    this.router.navigate(['home/back-office']);
  }
  
  // cerrarsesion():void{
  //   if(this.localSave.get('typeuser') == 0){
  //     this.localSave.delete('typeuser');
  //     this.localSave.delete('agenciaact');
  //     this.router.navigate(['login']);
  //   }else if(this.localSave.get('typeuser') == 1){
  //     this.localSave.delete('typeuser');
  //     this.localSave.delete('actejecutivo');
  //     this.router.navigate(['loginejecutivo']);
  //   }
  // }

  @Output() change: EventEmitter<boolean> = new EventEmitter();
  selectCountry(pais){
    this.localSave.set('country', pais);
    this.change.emit(pais);
    this.selected = this.localSave.get('country');
  }

}

@NgModule({
  providers: [ComponentPageTitle],
})
export class ComponentHeaderModule { }

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./home.component.css']
})

export class HomeMenuComponent {

  constructor(private componentPageTitle: ComponentPageTitle){

  }

  ngOnInit() {
		this.componentPageTitle.title = 'Menu principal';
	}

}

