import { Injectable } from '@angular/core';
import { LocalSaveService } from './local-save.service';
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class ValidatemenusService {

  permisos: any = []
  constructor(private localsave: LocalSaveService, private router: Router) { }

  setpermisos(permisos) {
    this.permisos = permisos;
  }

  permiso(menu) {
    let valido = this.permisos.map((menupermitido) => { return menupermitido }).indexOf(menu);
    if (valido != -1) {
      return true;
    } else {
      return false;
    }
  }

  firststate() {
    console.log(this.permisos.length)
    if (this.permisos.length == 0) {
      this.router.navigate(['login']);
    } else {
      let go;
      let auxstate;
      this.permisos.map((permiso)=>{
        let exclude = "'mnoBotscrear','mnoBroadcastcopiar','mnoBroadcastcrear','mnoBroadcasteditar','mnoBroadcasteliminar','mnoCasosabrir','mnoComandoscrear','mnoComandoseditar','mnoComandoseliminar','mnoNodoscrear','mnoNodoseliminar','mnoNodoseditar','mnoPerfilescrear','mnoPerfileseditar','mnoPerfileseliminar'";
        if(!exclude.includes(permiso)){
          auxstate = permiso;
        }
      });
      states.map((state) => {
        if(state.permiso == auxstate){
          go = state.link
        }
      });
      this.router.navigate([go]);
    }
  }

}
const states = [
  { 'name': 'Bots', 'link': 'home/bots', 'permiso': 'mnoBots' },
  { 'name': 'Broadcast', 'link': 'home/broadcasts', 'permiso': 'mnoBroadcast' },
  { 'name': 'Comandos', 'link': 'home/comandos', 'permiso': 'mnoComandos' },
  { 'name': 'Perfiles', 'link': 'home/perfiles', 'permiso': 'mnoPerfiles' },
  { 'name': 'Nodos', 'link': 'home/nodos', 'permiso': 'mnoNodos' },
  { 'name': 'Gestor de archivos', 'link': 'home/file-explorer', 'permiso': 'mnoGestorarchivos' },
  { 'name': 'Administrador de áreas ', 'link': 'home/area', 'permiso': 'mnoAdministradorareas' },
  { 'name': 'Gestión de planes y requisitos', 'link': 'home/planes-requisitos', 'permiso': 'mnoGestionplanesrequisitos' },
  { 'name': 'Casos', 'link': 'home/back-office', 'permiso': 'mnoCasos' },
  { 'name': 'Segmentos', 'link': 'home/segmento', 'permiso': 'mnoSegmentos' }
];
