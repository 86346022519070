import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalSaveService {

  constructor() { }

  set(key:string,value:any):void{
  	localStorage.setItem(key,JSON.stringify(value));
  }

  get(key:string):any{
  	return JSON.parse(localStorage.getItem(key));
  }

  delete(key:string):void{
  	localStorage.removeItem(key);
  }

  validate(key:string):boolean{
  	if(localStorage.getItem(key) == null){
  		return false;
  	}else{
  		return true;
  	}
  }
  
}
