import { Component, Input, Output } from 'rete';
import { textSocket } from '../structures/sockets';

export class QuickComponent extends Component {
  constructor() {
    super('quickresponse');
  }

  builder(node) {
    node.addInput(new Input('entrada', 'Entrada', textSocket, true));
    for(let i = 0; i<node.data.outputs.length;i++){
      node.addOutput(new Output('_quick'+node.data.outputs[i], node.data.outputs[i], textSocket, false));
    }
  }

  worker(node, inputs, outputs) {

  }

}
