import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { MatDialog, MatTableDataSource, PageEvent, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { deleteElement } from '../delete/eliminar-element.component';
import { ComponentPageTitle } from '../page-title/page-title';
import { ServicesService } from '../services.service';

@Component({
	selector: 'app-area',
	templateUrl: './area.component.html',
	styleUrls: ['./area.component.css']
})
export class AreaComponent implements OnInit {

	@ViewChild(MatPaginator) paginator: MatPaginator;
	displayedColumns = ['nombre', 'fecha_creacion', 'accion'];
	areas = new MatTableDataSource([]);
	filter_search = "";
	isLoadingResults = false;
	areaCount = 0;

	constructor(
		private router: Router, 
		private dialog: MatDialog, 
		private componentPageTitle: ComponentPageTitle, 
		private services: ServicesService
		) { }

	ngOnInit() {
		this.componentPageTitle.title = 'Administrador de Area';
		this.reload();
	}

	reload() {
		this.isLoadingResults = true;
		var filters = [this.filter_search, this.paginator.pageIndex];
		this.isLoadingResults = true;
		this.services.getArea(filters).subscribe(data => {
			this.areas = new MatTableDataSource(data);
			this.services.getAreaCount(filters).subscribe(data => {
				this.areaCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
	}

	addArea() {
		let dialogRef = this.dialog.open(ingresoArea, {
			disableClose: true,
			width: '1000px',
			height: '90%',
			data: {}
		});
		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	modifyArea(area) {
		let dialogRef = this.dialog.open(ingresoArea, {
			disableClose: true,
			width: '1000px',
			height: '90%',
			data: { area }
		});

		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	deleteArea(area) {
		let dialogRef = this.dialog.open(deleteElement, {
			disableClose: true,
			data: { area: area }
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.services.deleteArea({ codigo: area.codig_area_admon }).subscribe(data => {
					this.search();
				});
			}
		});
	}

	search() {
		this.filter_search = this.filter_search.trim();
		if (this.paginator.pageIndex > 0) {
			this.paginator.firstPage();
		}
		this.reload();
	}

}

@Component({
	selector: 'ingreso-area',
	templateUrl: 'ingreso-area.html',
	styles: ['::ng-deep.mat-tab-body-wrapper {height:100% !important} .example-table-container {position: relative;max-height: 400px;overflow: auto;}.example-loading-shade {position: absolute;top: 0;left: 0;bottom: 56px;right: 0;background: rgba(0, 0, 0, 0.15);z-index: 1;display: flex;align-items: center;justify-content: center;}.example-container {position: relative;}.mat-header-cell{text-align: center;}.mat-cell{text-align: center;}.mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;} .mat-form-field {	margin:5%;} .addButton{	float: bottom;	margin-top: -1%;	margin-bottom: 1%;	background-color:#00A9E0;} ::ng-deep .mat-sort-header-container { display:flex; justify-content:center;} th.mat-sort-header-sorted { color: black; text-align: center;} .mat-paginator-container{justify-content: center !important;} table { width: 100%;}']
})

export class ingresoArea {
	area;
	areaActiva = -1;
	nombreActivo = "";
	areaForm = new FormGroup({
		codigo_area: new FormControl(''),
		nombre: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(45), Validators.pattern('[0-9a-zA-Z ]*')])),
	});

	perfilDetalleForm = new FormGroup({
		frase: new FormControl('', Validators.required)
	});

	users = new MatTableDataSource([]);
	selected = 0;
	pageEvent: PageEvent;
	pageIndex = 0;
	filter_search = "";
	isLoadingResults = false;
	userDetailCount = 0;
	displayedColumns = ["usuario", "nombre", "asociado"];
	isEditing = false;

	frases: any[] = [];
	parametros: any[] = [];
	constructor(private dialogRef: MatDialogRef<ingresoArea>, @Inject(MAT_DIALOG_DATA) private data: any, public snackBar: MatSnackBar, public services: ServicesService, private dialog: MatDialog) {
		this.area = this.data.area;
		if (typeof (this.area) != 'undefined') {
			this.areaActiva = this.area.codigo_area;
			this.nombreActivo = this.area.nombre;
			this.areaForm.patchValue(this.area);
			this.isEditing = true;
			this.tabsel(0);
		}
		this.reload();
	}

	reload() {
		this.isLoadingResults = true;
		var params = { codigo_area: this.areaActiva, pageIndex: this.pageIndex * 3, filter_search: this.filter_search };
		this.services.getUsersByArea(params).subscribe(data => {
			this.users = new MatTableDataSource(data);
			this.services.getUsersByAreaCount(params).subscribe(data => {
				this.userDetailCount = data[0].total;
			});
			this.isLoadingResults = false;
		});
	}

	paginatorClick() {
		if (typeof (this.pageEvent) != 'undefined') {
			this.pageIndex = this.pageEvent.pageIndex;
		}
		this.reload();
	}

	tabsel(evt): void {
		this.selected = evt;
	}

	relateUser(usuario) {
		var params = { codigo_area: this.areaActiva, codigo_usuario_avi: usuario.codigo_usuario_avi }
		this.services.insertUserArea(params).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.reload();
			}
		});
	}

	search() {
		this.filter_search = this.filter_search.trim();
		this.reload();
	}

	modifyArea() {
		this.services.updateArea(this.areaForm.value).subscribe(data => {
			this.areaActiva = data[0].resultado;
			if (this.areaActiva != -1) {
				this.nombreActivo = this.areaForm.value.nombre;
				this.tabsel(1);
			}
			else {
				this.snackBar.open("Area ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		});
	}

	saveArea() {
		this.services.insertArea(this.areaForm.value).subscribe(data => {
			this.areaActiva = data[0].resultado;
			if (this.areaActiva != -1) {
				this.nombreActivo = this.areaForm.value.nombre;
				this.tabsel(1);
			}
			else {
				this.snackBar.open("Area ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		});
	}

	onNoClick(event): void {
		this.dialogRef.close(event);
	}

	getErrorMessage(element) {
		if (element.hasError('required')) {
			return "Campo requerido";
		} else if (element.hasError('maxlength')) {
			return "Longitud maxima de " + element.errors.maxlength.requiredLength + " caracteres";
		}
	}
}