import { Pipe, PipeTransform } from '@angular/core';
import { EmojiSearch } from '@ctrl/ngx-emoji-mart';

@Pipe({
	name: 'emojispipe',
	pure: true
})
export class EmojispipePipe implements PipeTransform {

	constructor(private emojiSearch: EmojiSearch) {}

	transform(array: any,param:any): any [] {
		let match:any;
		let nativearray:any [] = JSON.parse(JSON.stringify(array));
		array.map((chip,index)=>{
			var test = chip.match(/\:[a-zA-Z0-9\-\_]+\:/g);
			console.log(test);
			if(test){
				let newchip = ''; 
				let startslice = 0;
				test.map((emoji)=>{
					console.log(chip.indexOf(emoji));
					console.log(emoji.length);
					let startnative = chip.indexOf(emoji);

					emoji = emoji.replace(/:/g, "");
					let nativeemoji = this.emojiSearch.search(emoji).map(o => o.native)[0];
					let auxchip = chip.slice(startslice,startnative)+nativeemoji;
					newchip = newchip + auxchip;
					startslice = startnative+emoji.length+2;
				})
				nativearray[index]=newchip;
			}else{
				nativearray[index]=chip;
			}
			
		});
		return nativearray;
	}

}
