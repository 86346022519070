import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { EcoFabSpeedDialModule } from '@ecodev/fab-speed-dial';
import { FlexLayoutModule } from '@angular/flex-layout';

import * as emoji from 'node-emoji';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ComponentPageTitle } from './page-title/page-title';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { HomeComponent, HomeMenuComponent } from './home/home.component';
import { LoginComponent,errorLoginComponent } from './login/login.component';
import { routes } from './app.router';
import { LocalSaveService } from './local-save.service';
import { PerfilesComponent,ingresoPerfil } from './perfiles/perfiles.component';
import { AngularSplitModule } from 'angular-split';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import { PapaParseModule } from 'ngx-papaparse';
import { DragDropComponent,cargarNodo, ingresoDecision, ingresoTemplate } from './drag-drop/drag-drop.component'
import { DragDropComponent2,cargarNodo2, ingresoDecision2, ingresoTemplate2 } from './tigowpbot/drag-drop.component'
import { BotsComponent, ingresoBot } from './bots/bots.component';
import { MatCheckboxModule, MatDialogRef, MAT_DIALOG_DATA, MatPaginatorIntl } from '@angular/material';
import { NodosComponent, ingresoNodo } from './nodos/nodos.component';
import { BroadcastComponent, ingresoBroadcast } from './broadcasts/broadcast.component';
import { FileExplorerComponent, FolderDialogComponent, FormUploadComponent } from './file-explorer/file-explorer.component';
import { ComandosComponent, ingresoComandos } from './comandos/comandos.component';
import { deleteElement } from './delete/eliminar-element.component';
import { JustificacionComponent } from './justificacion/justificacion.component';
import { EmojisComponent } from './emojis/emojis.component';
import { MenuinicialComponent } from './menuinicial/menuinicial.component';
import { AreaComponent, ingresoArea } from './areas/area.component';
import { Shorten } from '../utils/shorten';
import { RequisitosComponent, ingresoRequisito } from './planes-requisitos/requisitos/requisitos.component';
import { PlansRequirementsComponent } from './planes-requisitos/planes-requisitos.component';
import { ProductsComponent } from './planes-requisitos/productos/productos.component';
import { WebServiceComponent } from './comandos/web-service/web-service.component';
import { CustomMatPaginatorIntl } from '../custom-mat-paginator';
import {TranslateModule} from '@ngx-translate/core';
import { PlansComponent } from './planes-requisitos/planes/planes.component';
import { BackOfficeComponent, verBackOffice } from './back-office/back-office.component';
import { FamiliesComponent, ingresoFamilia } from './planes-requisitos/familia/familias.component';
import { SegmentoComponent } from './segmento/segmento.component';
import { CrearsegmentoComponent } from './crearsegmento/crearsegmento.component';
import { ConfigurationComponent, ingresoConfiguracion, verRequisitos, resultadoInsercion } from './planes-requisitos/configuracion/configuraciones.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { EmojispipePipe } from './emojispipe.pipe';


@NgModule({
    declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    errorLoginComponent,
    PerfilesComponent,
    ingresoPerfil,
    DragDropComponent,
    DragDropComponent2,
    cargarNodo2,
    ingresoDecision2,
    ingresoTemplate2,
    cargarNodo,
    BotsComponent,
    BroadcastComponent,
    NodosComponent,
    ingresoNodo,
    ingresoBot,
    ingresoBroadcast,
    FileExplorerComponent,
    FolderDialogComponent,
    FormUploadComponent,
    ComandosComponent,
    ingresoComandos,
    deleteElement,
    JustificacionComponent,
    AreaComponent,
    ingresoArea,
    ingresoDecision,
    ingresoTemplate,
    Shorten,
    RequisitosComponent,
    ProductsComponent,
    PlansComponent,
    PlansRequirementsComponent,
    ingresoRequisito,
    WebServiceComponent,
    BackOfficeComponent,
    verBackOffice,
    FamiliesComponent,
    ingresoFamilia,
    ConfigurationComponent,
    ingresoConfiguracion,
    verRequisitos,
    resultadoInsercion,
    HomeMenuComponent,
    EmojisComponent,
    EmojispipePipe,
    MenuinicialComponent,
    SegmentoComponent,
    CrearsegmentoComponent
    ],
    imports: [
    PickerModule,
    BrowserModule,
    AngularSplitModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    routes,
    HttpClientModule,
    PapaParseModule,
    EcoFabSpeedDialModule,
    MatCheckboxModule,
    FlexLayoutModule,
    TranslateModule.forRoot(),
    NgxMatSelectSearchModule,
    
    ],
    entryComponents: [CrearsegmentoComponent,EmojisComponent,ingresoPerfil, errorLoginComponent, ingresoNodo, cargarNodo, ingresoBot, ingresoBroadcast, FolderDialogComponent, FormUploadComponent, ingresoComandos, deleteElement,JustificacionComponent, FileExplorerComponent, ingresoArea, ingresoDecision, ingresoTemplate, ingresoRequisito, verBackOffice, ingresoFamilia, ingresoConfiguracion, verRequisitos, resultadoInsercion],
    providers: [
    LocalSaveService, ComponentPageTitle, 
    {provide:MatDialogRef, useValue:{}},
    {provide:MAT_DIALOG_DATA, useValue:[]},
    {provide: MAT_DATE_LOCALE, useValue: "es-GT" },
    {provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
