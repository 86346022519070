import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { MatPaginator, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSortModule, MatSort, MatSnackBar, MatDatepickerInputEvent } from '@angular/material';
import { HttpClient } from '@angular/common/http';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import {PageEvent} from '@angular/material';
import { Papa } from 'ngx-papaparse';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import {map, startWith, takeUntil} from 'rxjs/operators';
import { deleteElement } from '../delete/eliminar-element.component';
import { LocalSaveService } from '../local-save.service';
import { ServicesService } from '../services.service';
import { ComponentPageTitle } from '../page-title/page-title';
import { FileExplorerComponent } from '../file-explorer/file-explorer.component';
import {MatChipInputEvent} from '@angular/material';
import { EmojiSearch } from '@ctrl/ngx-emoji-mart';
import * as emoji from 'node-emoji';
import { EmojisComponent } from '../emojis/emojis.component';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { ValidatemenusService } from '../validatemenus.service';
@Component({
	selector: 'app-broadcast',
	templateUrl: './broadcast.component.html',
	styleUrls: ['./broadcast.component.css']
})
export class BroadcastComponent implements OnInit {

	estados:any=[];
	@ViewChild(MatPaginator) paginator : MatPaginator;
	displayedColumns = ['nombre', 'descripcion', 'estado_broadcast', 'bot', 'tipo_envio', 'fecha_ejecucion', 'hora_ejecucion', 'fecha_creacion', "accion"];
	broadcasts = new MatTableDataSource([]);
	filter_search = "";
	filter_estado = "";
	filter_bot = "-1";

	isLoadingResults = false;
	broadcastCount = 0;
	bots;
	filteredBots: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
	formBots = new FormGroup({
		bot: new FormControl('-1', Validators.required),
		filterBots: new FormControl('', [])
  });
	protected _onDestroy = new Subject<void>();
	
	constructor(
		private componentPageTitle: ComponentPageTitle, 
		private router: Router, 
		private localSave: LocalSaveService, 
		public dialog: MatDialog, 
		private http: HttpClient, 
		private validatemenu: ValidatemenusService,
		public services: ServicesService
		) { }

	ngOnInit() {
		this.componentPageTitle.title = 'Broadcasts';
		this.reload();
		this.reloadBots();
	}

	reload(){
		var filters = [this.filter_search, this.filter_estado, this.paginator.pageIndex, this.filter_bot];
		console.log(this.formBots);
		console.log(filters);
		this.isLoadingResults = true;
		this.services.getBroadcasts(filters).subscribe(data => {
			for(let d of data){
				d.fecha_ejecucion = new Date(d.fecha_ejecucion);
			}
			this.broadcasts = new MatTableDataSource(data);
			this.services.getBroadcastsCount(filters).subscribe(data => {
				this.broadcastCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
	}

	reloadBots(){
		this.isLoadingResults = true;
		this.services.getBotsBroadcast([]).subscribe(data =>{
			this.bots = data;
			this.filteredBots.next(this.bots.slice());
			this.isLoadingResults = false;
			this.formBots.controls.filterBots.valueChanges
			  .pipe(takeUntil(this._onDestroy))
			  .subscribe(() => {
				this.filterBot();
			  })
		});
	}

	fnvalidatemenu(menu) {
		return this.validatemenu.permiso(menu);
	}

	ngOnDestroy() {
		this._onDestroy.next();
		this._onDestroy.complete();
	}

	filterBot() {
		if (!this.bots) return;
		let search = this.formBots.controls.filterBots.value;
		if (!search) {
		  this.filteredBots.next(this.bots.slice());
		} else {
		  search = search.toLowerCase();
		  this.filteredBots.next(
			this.bots.filter(bot => bot.nombre.toLowerCase().indexOf(search) > -1)
		  );
		}
	  }

	search(){
		this.filter_search = this.filter_search.trim();
		this.filter_estado = this.filter_estado.trim();
		this.filter_bot = this.formBots.value.bot;
		if(this.paginator.pageIndex > 0){
			this.paginator.firstPage();
		}
		this.reload();
	}

	ingresoBroadcast(){
		let dialogRef = this.dialog.open(ingresoBroadcast, {
			disableClose: true,
			width: '1000px',
			height: '90%',
			data: {}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	modify(broadcast){
		let dialogRef = this.dialog.open(ingresoBroadcast, {
			disableClose: true,
			width: '1000px',
			height: '90%',
			data: {broadcast}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	delete(broadcast){
		let dialogRef = this.dialog.open(deleteElement, {
			disableClose: true,
			data: {broadcast}
		  });
			dialogRef.afterClosed().subscribe(result => {
			if(result){
				this.services.deleteBroadcast({codigo_broadcast: broadcast.codigo_broadcast,nombre:broadcast.nombre}).subscribe(data => {
					this.search();
				});
			}
		  });
	}

	copy(broadcast){
		broadcast.estado_broadcast = "detenido";
		let dialogRef = this.dialog.open(ingresoBroadcast, {
			disableClose: true,
			width: '1000px',
			height: '90%',
			data: {broadcast, es_copia: true}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}
}

@Component({
	selector: 'ingresoBroadcast',
	templateUrl: 'ingreso-broadcast.html',
	styles: ['.mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;}']
})

export class ingresoBroadcast{

	min_date = new Date();
	min_time: boolean = false;
	broadcastActivo = -1;
	nombreActivo = "";
	es_copia;
	broadcastForm = new FormGroup({
		codigo_broadcast: new FormControl(),
		nombre: new FormControl('', [Validators.required, Validators.maxLength(45)]),
		descripcion: new FormControl('', [Validators.required, Validators.maxLength(300)]),
		fecha_ejecucion:new FormControl ('', Validators.required),
		hora_ejecucion: new FormControl('', Validators.required),
		estado_broadcast: new FormControl('', Validators.required),
		codigo_bot: new FormControl('', Validators.required),
		bot: new FormControl('', Validators.required),
		// codigo_nodo: new FormControl('', Validators.required),
		tipo_envio: new FormControl('', Validators.required),
		// nodo: new FormControl('', Validators.required),
		im: new FormControl('1'),
		parametro: new FormControl('', [Validators.required, Validators.maxLength(498)]),
		codigo_parametro: new FormControl(''),
		direccion_parametro: new FormControl(''),
		testchip: new FormControl()
	});

	broadcast:any;
	pageEvent: PageEvent;
	pageIndex = 0;
	filteredComandos: Observable<string[]>;
	comandos: any=[];
	filteredBots: Observable<string[]>;
	bots: any=[];
	nativeemojis:any[] = []; 
	parametros: any[] = [];
	isMultimedia:boolean = false;
	readonly separatorKeysCodes: number[] = [ENTER];
	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	

	constructor(private emojiSearch: EmojiSearch,public dialogRef: MatDialogRef<ingresoBroadcast>, @Inject(MAT_DIALOG_DATA) public data: any, private localSave: LocalSaveService, private http: HttpClient, public snackBar: MatSnackBar, public services: ServicesService, private papa: Papa,private dialog: MatDialog) {
		this.broadcast = this.data.broadcast;
		this.es_copia = this.data.es_copia;
		this.reload();
	}

	reload(){
		if(typeof(this.broadcast) != 'undefined'){
			this.broadcastActivo = this.broadcast.codigo_broadcast;
			this.nombreActivo = this.broadcast.nombre;
			this.broadcastForm.patchValue(this.broadcast);
			let ejecucion = new Date(this.broadcastForm.value.fecha_ejecucion);
			if(this.checkIfSameDate(this.min_date, ejecucion)){
				this.min_time = true;
			}
			this.broadcastForm.controls.fecha_ejecucion.markAsDirty();
			this.broadcastForm.controls.fecha_ejecucion.markAsTouched();
		}
		// this.services.getComandosBroadcast([]).subscribe(data => {
		// 	this.comandos = data;
		// 	if(typeof(this.broadcast) != 'undefined'){
		// 		this.objComandos(this.comandos.find(x => x.codigo_nodo === this.broadcast.codigo_nodo));
		// 	}
		// 	this.filteredComandos = this.broadcastForm.controls.nodo.valueChanges
		// 		.pipe(
		// 			startWith<string | any>(''),
		// 			map(value => typeof value === 'string' ? value : value.nombre),
		// 			map(nombre => nombre ? this._filter(nombre) : this.comandos.slice())
		// 		);
			this.services.getBotsBroadcast([]).subscribe(data => {
				this.bots = data;
				if(typeof(this.broadcast) != 'undefined'){
					this.objBots(this.bots.find(x => x.codigo_bot === this.broadcast.codigo_bot));
				}
				this.filteredBots = this.broadcastForm.controls.bot.valueChanges
					.pipe(
						startWith<string | any>(''),
						map(value => typeof value === 'string' ? value : value.nombre),
						map(nombre => nombre ? this._filterBot(nombre) : this.bots.slice())
					);
			});
		// });
	}

	saveBroadcast() {
		if(this.checkDate()){
			this.snackBar.open("La hora no puede ser menor a la hora actual, ya que la fecha es el dia de hoy", '', { duration: 3000 });
		}else{
			this.broadcastForm.value.parametro=emoji.unemojify(this.broadcastForm.value.parametro);
			this.services.insertBroadcast(this.broadcastForm.value).subscribe(data => {
				var resultado = data[0].resultado;
				if (resultado != -1) {
					this.broadcastActivo = resultado;
					this.nombreActivo = this.broadcastForm.value.nombre;
					this.dialogRef.close();
				}
				else
				{
					this.snackBar.open("Broadcast ya existente, ingrese otro nombre", '', { duration: 3000 });
				}
			});
		} 
	}

	modifyBroadcast() {
		let vari = this.checkDate();
		if(vari){
			this.snackBar.open("La hora no puede ser menor a la hora actual, ya que la fecha es el dia de hoy", '', { duration: 3000 });
		}else{
			this.services.updateBroadcast(this.broadcastForm.value).subscribe(data => {
				var resultado = data[0].resultado;
				if (resultado != -1) {
					this.broadcastActivo = resultado;
					this.nombreActivo = this.broadcastForm.value.nombre;
					this.dialogRef.close();
				}
				else {
					this.snackBar.open("Broadcast ya existente, ingrese otro nombre", '', { duration: 3000 });
				}
			});
		}
   }

   checkDate(): boolean{
		if(this.min_time){
			let hour_component = this.broadcastForm.value.hora_ejecucion.split(":");
			let hour = hour_component[0];
			let minutes = hour_component[1];
			if(+hour < this.min_date.getHours()){
				return true;		
			}else if(+hour == this.min_date.getHours() && +minutes < this.min_date.getMinutes()){
				return true;
			}else{
				return false;
			}
		}
		return false;
   }
	onNoClick(): void {
		this.dialogRef.close();
	}

	selectComando(value){
		this.broadcastForm.controls.codigo_nodo.setValue(value.codigo_nodo);
		this.broadcastForm.controls.nodo.setValue(value);
	}

	selectBot(value){
		this.broadcastForm.controls.codigo_bot.setValue(value.codigo_bot);
		this.broadcastForm.controls.bot.setValue(value);
	}

	displayFn(user?: any): string | undefined {
		return user ? user.nombre : undefined;
	}
	
	_filter(value: string): any {
		const filterValue = value.toLowerCase();
		return this.comandos.filter(option => option.nombre.toLowerCase().includes(filterValue));
	}

	_filterBot(value: string): any {
		const filterValue = value.toLowerCase();
		return this.bots.filter(option => option.nombre.toLowerCase().includes(filterValue));
	}

	objComandos(model){
		this.broadcastForm.controls.nodo.setValue(model);
	}

	objBots(model){
		this.broadcastForm.controls.bot.setValue(model);
	}

	dateEvent(event: MatDatepickerInputEvent<Date>){
		if(this.checkIfSameDate(this.min_date,event.value)){
			this.min_time = true;
		}else{
			this.min_time = false;
		}
	}

	checkIfSameDate(date1: Date, date2: Date): boolean{
		if(date1.getDate() === date2.getDate() && date1.getMonth() === date1.getMonth() && date1.getFullYear() === date2.getFullYear()){
			return true;
		}
		return false;
	}

	getErrorMessage(element){
		if(element.hasError('required')){
			return "Campo requerido";
		}else if(element.hasError('maxlength')){
			return "Longitud maxima de " + element.errors.maxlength.requiredLength + " caracteres";
		}else if(element.hasError('matDatepickerMin')){
			return "La fecha debe ser mayor al dia de hoy";
		}
	}

	// FUNCIONES DE INGRESO NODO COMPONENT
	loadnativeemojis():void {
		this.nativeemojis = [];
		this.parametros.map((chip)=>{
			console.log(chip);
			this.nativeemojis.push(emoji.emojify(chip));
			console.log(chip);
		});
		console.log(this.nativeemojis);
		
	}
	add2(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;
		if ((value || '').trim()) {
			this.parametros.push(value.trim());
			this.loadnativeemojis();
		}
		if (input) {
			input.value = '';
		}
		if(this.parametros.length === 1){
			this.broadcastForm.controls.parametro.setValue(value.trim());
		}
	}

	remove2(frase: any): void {
		const index = this.nativeemojis.indexOf(frase);
		if (index >= 0) {
			this.parametros.splice(index, 1);
			this.nativeemojis.splice(index, 1);
		}
		if(this.parametros.length == 0){
			this.broadcastForm.controls.parametro.setValue('');
		}
	}
	openExplorer(){
		let dialogRef = this.dialog.open(FileExplorerComponent, {
			panelClass: 'my-panel',
			width: '100%',
			height: '90%',
			maxWidth: '100vw',
			disableClose: true,
			data: {isCommand: true}
		});
		dialogRef.afterClosed().subscribe( result => {
			if(result){
				const selectedFile = dialogRef.componentInstance.selectedFile;
				// this.broadcastForm.controls.codigo_parametro.setValue(selectedFile.codigo_archivo);
				// this.broadcastForm.controls.parametro.setValue(selectedFile.nombre);
				this.broadcastForm.controls.parametro.setValue(selectedFile.direccion);
				// this.parametros.push(this.broadcastForm.value.parametro);
				// this.loadnativeemojis();
			}
		});
	}
	updateType(evt){
		console.log(evt.value)
		if(evt.value=="file"){
			this.isMultimedia=true;
		}else{
			this.isMultimedia=false;
		}
		
	}

	openDialog(): void {
		const dialogRef = this.dialog.open(EmojisComponent, {
			data: {}
		});

		dialogRef.afterClosed().subscribe(result => {
			// console.log(this.broadcastForm.controls.testchip.value);
			// console.log(result);
			let auxvalue = this.broadcastForm.controls.parametro.value;
			
			this.broadcastForm.controls.parametro.setValue(auxvalue + emoji.emojify(result.colons));

		});
	}
	// FUNCIONES DE INGRESO NODO COMPONENT

}