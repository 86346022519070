import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServicesService } from '../services.service';
import { MatDialog, MatTableDataSource, PageEvent, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';

@Component({
  selector: 'app-crearsegmento',
  templateUrl: './crearsegmento.component.html',
  styleUrls: ['./crearsegmento.component.css']
})
export class CrearsegmentoComponent implements OnInit {

  paises: any;
  createsegmentoloder: boolean = false;
  isEditing = false;

  constructor(
    private services: ServicesService,
    private dialogref: MatDialogRef<CrearsegmentoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
  ) { }

  ngOnInit() {
    this.getpais();
    this.isEditing = this.data.modify;
    if(this.isEditing)
    {
      var segmento = this.data.segmento;
      this.segmentoForm.controls.nombre.setValue(segmento.nombre);
    }
  }

  getpais() {
    this.services.getCountries().subscribe(data => {
      console.log(data);
      this.paises = data;
    });
  }

  segmentoForm = new FormGroup({
    nombre: new FormControl('', Validators.compose([Validators.required, Validators.maxLength(45), Validators.pattern('[0-9a-zA-Z ]*')])),
    pais: new FormControl('', [Validators.required]),
  });

  saveSegmento(index) {
    this.createsegmentoloder = true;
    let auxpais = this.segmentoForm.controls.pais.value;
    console.log(auxpais);
    console.log(auxpais.length);
    if (index < auxpais.length - 1) {
      console.log({ nombre: this.segmentoForm.controls.nombre.value, pais: auxpais[index] });
      this.services.insertSegment({ nombre: this.segmentoForm.controls.nombre.value, pais: auxpais[index] }).subscribe(data => {
        console.log(data);
        this.saveSegmento(++index);
      });
    } else {
      this.services.insertSegment({ nombre: this.segmentoForm.controls.nombre.value, pais: auxpais[index] }).subscribe(data => {
        this.createsegmentoloder = false;
        this.dialogref.close(true);
      });
    }
  }

  close(){
    this.dialogref.close(false);
  }

  getErrorMessage(error) {
    if(error == 0){
      if(this.segmentoForm.controls.nombre.getError('required')){
        return 'Debes de Ingresar un Nombre';
      }
      else
      if(this.segmentoForm.controls.nombre.getError('maxlength')){
        return 'Sobrepasaste el número de caracteres (45)';
      }
    }
    else if(error == 1){
      if(this.segmentoForm.controls.pais.getError('required')){
        return 'Debes seleccionar al menos 1 país'
      }
    }
  }
}
