import { TextComponent } from '../components/text-component';
import { StartComponent } from '../components/start-component';
import { MenuComponent } from '../components/menu-component';
import { CommandComponent } from '../components/command-component';
import { NodeEditor } from 'rete';
import { DecisionComponent } from '../components/decision-component';
import { SelectionComponent } from '../components/selection-component';
import { SecurityComponent } from '../components/security-component';
import { DpiComponent } from '../components/dpi-component';
import { FbTemplateComponent } from "../components/template-component";
import { QuickComponent } from '../components/quick-component';

export class Board{

    private components = [new MenuComponent(), new CommandComponent(), new StartComponent(), new TextComponent(), new DecisionComponent(), new SelectionComponent(), new SecurityComponent(), new DpiComponent(), new FbTemplateComponent(), new QuickComponent];
		private posX: number = 0;
		private posY: number = 0;

		constructor(private editor:NodeEditor){}		

		async addMenu(name, code, bot, counter, type, outputs){
			const n = await this.components[0].createNode({name : name, code:code, bot: bot, type: type ,outputs : this.convertToArray(outputs, "menu"), collapse: false, posX : 0, posY : 0});
			n.position = [this.posX, this.posY];
			this.editor.addNode(n);
			this.editor.selectNode(n);
		}

		async addSeleccion(name, code, bot, counter, type, outputs){
			const n = await this.components[5].createNode({name : name, code:code, bot: bot, type: type ,outputs : this.convertToArray(outputs, "menu"), collapse: false, posX : 0, posY : 0});
			n.position = [this.posX, this.posY];
			this.editor.addNode(n);
			this.editor.selectNode(n);
		}

		async addDecision(name, code, bot, counter, type, outputs){
			const n = await this.components[4].createNode({name : name, code:code, bot: bot, type: type ,outputs : this.convertToArray(outputs, "decision"), collapse: false, posX : 0, posY : 0});
			n.position = [this.posX, this.posY];
			this.editor.addNode(n);
			this.editor.selectNode(n);
		}
			
		async addInicio(name, bot, type){
			const n = await this.components[2].createNode({name: name, bot: bot, type : type, collapse: false, posX : 0, posY : 0});
			n.position = [0, 0];
			this.editor.addNode(n);
		}
	
		async addTexto(name, code, bot, counter, type){
			const n = await this.components[3].createNode({name : name, code: code, bot: bot, type: type, collapse: false,  posX : 0, posY : 0});
			n.position = [this.posX, this.posY];
			this.editor.addNode(n);
			this.editor.selectNode(n);
		}
	
		async addComando(name, code, bot, counter){
			const n = await this.components[1].createNode({name : name, code: code, bot: bot, type: 'comando', collapse: false,  posX : 0, posY : 0});
			n.position = [this.posX, this.posY];
			this.editor.addNode(n);
			this.editor.selectNode(n);
		}

		async addSecurity(name, bot, type)
		{
			const n = await this.components[6].createNode({name: name, bot: bot, type : type, collapse: false, posX : 0, posY : 0});
			n.position = [this.posX, this.posY];
			this.editor.addNode(n);
			this.editor.selectNode(n);
		}

		async addDpi(name, bot, type)
		{
			const n = await this.components[7].createNode({name: name, bot: bot, type : type, collapse: false, posX : 0, posY : 0});
			n.position = [this.posX, this.posY];
			this.editor.addNode(n);
			this.editor.selectNode(n);
		}

		async addTemplate(name, code, bot, type, outputs)
		{
			console.log(outputs)
			const n = await this.components[8].createNode({name : name, bot: bot, code: code, type: type ,outputs : outputs, collapse: false, posX : 0, posY : 0});
			n.position = [this.posX, this.posY];
			this.editor.addNode(n);
			this.editor.selectNode(n);
		}
		
		async addQuick(name, code, bot, type, outputs)
		{
			const n = await this.components[9].createNode({name : name, bot: bot, code: code, type: type ,outputs : this.convertToArray(outputs, "quickresponse"), collapse: false, posX : 0, posY : 0});
			n.position = [this.posX, this.posY];
			this.editor.addNode(n);
			this.editor.selectNode(n);
		}

		getComponents(){
			return this.components;
		}

		getEditor(){
			return this.editor;
		}

		convertToArray(data, type){
			let array: string[] = [];
			if(type != "decision"){
				for(let out of data){
					array.push(out.parametro);
				}
			}else{
				for(let out of data){
					array.push(out.condicion1+ " " + out.signo_relacional + " " + out.condicion2);
				}
			}
			return array;
		}

		setPositions(posX: number, posY: number){
			
			// this.posX = posX-60;
			// this.posY = posY-225;
			this.posX = posX;
			this.posY = posY;
		}

}