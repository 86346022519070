import { Component, OnInit, Inject } from '@angular/core';
import { ComponentPageTitle } from '../page-title/page-title';
import { PageEvent } from '@angular/material';
import { HomeComponent } from '../home/home.component';

@Component({
    selector: 'app-planes-requisitos',
    templateUrl: './planes-requisitos.component.html',
    styleUrls: ['./planes-requisitos.component.css']
  })
  
  export class PlansRequirementsComponent implements OnInit {


    filter_search = "";
	isLoadingResults = false;
	pageEvent: PageEvent;
	profileCount = 0;
    pageIndex = 0;
    
    ngOnInit(): void {
        this.componentPageTitle.title = 'Planes y requisitos';
    }

    constructor(private componentPageTitle: ComponentPageTitle, private home: HomeComponent){ 
        this.home.gestion = true;
    }


  }