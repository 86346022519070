import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { MatDialog, MatTableDataSource, PageEvent, MatDialogRef, MatSnackBar, MAT_DIALOG_DATA, MatPaginator } from '@angular/material';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { deleteElement } from '../delete/eliminar-element.component';
import { CrearsegmentoComponent } from '../crearsegmento/crearsegmento.component';
import { ComponentPageTitle } from '../page-title/page-title';
import { ServicesService } from '../services.service';


@Component({
	selector: 'app-segmento',
	templateUrl: './segmento.component.html',
	styleUrls: ['./segmento.component.css']
})
export class SegmentoComponent implements OnInit {

	@ViewChild(MatPaginator) paginator: MatPaginator;
	displayedColumns = ['nombre', 'pais', 'accion'];
	areas = new MatTableDataSource([]);
	filter_search = "";
	isLoadingResults = false;
	areaCount = 0;
	selected:number= -1;
	paises = [
		{ nombre: 'Todos', img: '', codigo_pais: -1, AREA: '', flag: '' },
		{ nombre: 'Guatemala', img: './assets/guatemala.png', codigo_pais: 1, flag: './assets/guatemala1.png' },
		{ nombre: 'El Salvador', img: './assets/salvador.png', codigo_pais: 2, flag: './assets/salvador1.png' },
		{ nombre: 'Nicaragua', img: './assets/nicaragua.png', codigo_pais: 5, flag: './assets/nicaragua1.png' },
		{ nombre: 'Costa Rica', img: './assets/costarica.png', codigo_pais: 3, flag: './assets/costarica1.png' },
		{ nombre: 'Panamá', img: './assets/panama.png', codigo_pais: 4, flag: './assets/panama1.png' }
	];


	constructor(
		private router: Router,
		private dialog: MatDialog,
		private componentPageTitle: ComponentPageTitle,
		private services: ServicesService
	) { }

	ngOnInit() {
		this.componentPageTitle.title = 'Administrador de Segmento';
		this.reload();
		this.getsegmento();
	}

	reload() {
		this.isLoadingResults = true;
		var filters = {nombre:this.filter_search, indice: this.paginator.pageIndex,pais: this.selected};
		console.log(filters);
		this.services.getSegments(filters).subscribe(data => {
			console.log(data);
			this.areas = new MatTableDataSource(data);
			this.services.getSegmentsCount(filters).subscribe(data => {
				console.log(data);
				if(data){
					this.areaCount = data[0].total;					
				}else{
					this.areaCount = 0;
				}
				this.isLoadingResults = false;
			});
		});
	}

	getsegmento() {
		this.isLoadingResults = true;
		var filters = [this.filter_search, this.paginator.pageIndex];
		this.isLoadingResults = true;
		this.services.getSegments({ nombre: this.filter_search, pais: this.selected, indice: 0 }).subscribe(data => {
			console.log(data);
			// getSegmentsCount
			this.services.getSegmentsCount({ nombre: '', pais: this.selected}).subscribe(data => {
				console.log(data);
			});
		});
	}

	addArea() {
		let dialogRef = this.dialog.open(CrearsegmentoComponent, {
			disableClose: true,
			width: '1000px',
			data: {modify: false}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.search();
			}
		});
	}

	modifyArea(segmento) {
		let dialogRef = this.dialog.open(CrearsegmentoComponent, {
		 	disableClose: true,
		 	width: '1000px',
		 	data: { segmento: segmento, modify: true }
		 });

		 dialogRef.afterClosed().subscribe(result => {
		 	this.search();
		 });
	}

	deleteSegmento(segmento) {
		let dialogRef = this.dialog.open(deleteElement, {
			disableClose: true,
			data: { segmento: segmento }
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.services.deleteSegment({ codigo: segmento.codigo_segmento }).subscribe(data => {
					this.search();
				});
			}
		});
	}

	search() {
		this.filter_search = this.filter_search.trim();
		if (this.paginator.pageIndex > 0) {
			this.paginator.firstPage();
		}
		this.reload();
	}

}