import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import { ComponentPageTitle } from '../page-title/page-title';
import { ValidatemenusService } from '../validatemenus.service';

@Component({
  selector: 'app-menuinicial',
  templateUrl: './menuinicial.component.html',
  styleUrls: ['./menuinicial.component.css']
})
export class MenuinicialComponent implements OnInit {
  wheight:string;
  menus:any=[];
  constructor(private validatemenu:ValidatemenusService, private router:Router,public componentPageTitle: ComponentPageTitle) { }

  ngOnInit() {
    this.wheight= (window.innerHeight-72)+'px';
    this.componentPageTitle.title='Menú Principal';
    this.loadmenus();
  }

  loadmenus(){
    let auxmenus = [
    {'name':'Bots','image':'./assets/newbot.png','link':'home/bots','permiso':'mnoBots'},
    {'name':'Broadcast','image':'./assets/broadcast.png','link':'home/broadcasts','permiso':'mnoBroadcast'},
    {'name':'Comandos','image':'./assets/coding.png','link':'home/comandos','permiso':'mnoComandos'},
    {'name':'Perfiles','image':'./assets/resume.png','link':'home/perfiles','permiso':'mnoPerfiles'},
    {'name':'Nodos','image':'./assets/analytics.png','link':'home/nodos','permiso':'mnoNodos'},
    {'name':'Gestor de archivos','image':'./assets/folder.png','link':'home/file-explorer','permiso':'mnoGestorarchivos'},
    {'name':'Administrador de áreas ','image':'./assets/administrator.png','link':'home/area','permiso':'mnoAdministradorareas'},
    {'name':'Gestión de planes y requisitos','image':'./assets/fill-up.png','link':'home/planes-requisitos','permiso':'mnoGestionplanesrequisitos'},
    {'name':'Casos','image':'./assets/archive.png','link':'home/back-office','permiso':'mnoCasos'}
    ];
    auxmenus.map((menu)=>{
      if(this.fnvalidatemenu(menu.permiso)){
        this.menus.push(menu);
      }
    });
  }

  fnvalidatemenu(menu){
    return this.validatemenu.permiso(menu);
  }

  goto(link){
    this.router.navigate([link]);
  }

}
