import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { Router } from "@angular/router";
import { MatPaginator, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSortModule, MatSort, MatSnackBar } from '@angular/material';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import { PageEvent } from '@angular/material';
import { Papa } from 'ngx-papaparse';
import { deleteElement } from '../delete/eliminar-element.component';
import { LocalSaveService } from '../local-save.service';
import { ServicesService } from '../services.service';
import { ComponentPageTitle } from '../page-title/page-title';
import { saveAs } from 'file-saver';
import { SelectionModel } from '@angular/cdk/collections';
import { ValidatemenusService } from '../validatemenus.service';

@Component({
	selector: 'app-perfiles',
	templateUrl: './perfiles.component.html',
	styleUrls: ['./perfiles.component.css']
})
export class PerfilesComponent implements OnInit {

	@ViewChild(MatPaginator) paginator: MatPaginator;
	displayedColumns = ['nombre', 'descripcion', 'total_numeros', 'fecha_creacion', 'accion'];
	profiles = new MatTableDataSource([]);
	filter_search = "";
	isLoadingResults = false;
	profileCount = 0;

	constructor(
		private componentPageTitle: ComponentPageTitle, 
		private router: Router, 
		private localSave: LocalSaveService, 
		private dialog: MatDialog, 
		private validatemenu: ValidatemenusService,
		private services: ServicesService
		) { }

	ngOnInit() {
		this.componentPageTitle.title = 'Perfiles';
		this.reload();
	}

	fnvalidatemenu(menu) {
		return this.validatemenu.permiso(menu);
	}

	reload() {
		var filters = [this.filter_search, this.paginator.pageIndex];
		this.isLoadingResults = true;
		this.services.getProfiles(filters).subscribe(data => {
			this.profiles = new MatTableDataSource(data);
			this.services.getProfilesCount(filters).subscribe(data => {
				this.profileCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
	}

	search() {
		this.filter_search = this.filter_search.trim();
		if (this.paginator.pageIndex > 0) {
			this.paginator.firstPage();
		}
		this.reload();
	}

	ingresoPerfil() {
		let dialogRef = this.dialog.open(ingresoPerfil, {
			disableClose: true,
			width: '1000px',
			height: '90%',
			data: {}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	modificar(profile) {
		let dialogRef = this.dialog.open(ingresoPerfil, {
			disableClose: true,
			width: '1000px',
			height: '90%',
			data: { profile }
		});
		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	eliminar(profile) {
		let dialogRef = this.dialog.open(deleteElement, {
			disableClose: true,
			data: { profile }
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				this.services.deleteProfile({ codigo: profile.codigo_perfil }).subscribe(data => {
					this.search();
				});
			}
		});
	}
}

@Component({
	selector: 'ingreso-perfil',
	templateUrl: 'ingreso-perfil.html',
	styles: ['::ng-deep.mat-tab-body-wrapper {height:100% !important} .example-table-container {position: relative;max-height: 400px;overflow: auto;}.example-loading-shade {position: absolute;top: 0;left: 0;bottom: 56px;right: 0;background: rgba(0, 0, 0, 0.15);z-index: 1;display: flex;align-items: center;justify-content: center;}.example-container {position: relative;}.mat-header-cell{text-align: center;}.mat-cell{text-align: center;}.mat-form-field {	margin:5%;} .addButton{	float: bottom;	margin-top: -1%;	margin-bottom: 1%;	background-color:#00A9E0;} ::ng-deep .mat-sort-header-container { display:flex; justify-content:center;} th.mat-sort-header-sorted { color: black; text-align: center;} .mat-paginator-container{justify-content: center !important;} table { width: 100%;}']
})

export class ingresoPerfil {

	@ViewChild(MatPaginator) paginator: MatPaginator;
	perfilActivo = -1;
	nombreActivo = "";
	filter_search = "";
	perfilForm = new FormGroup({
		codigo_perfil: new FormControl(''),
		nombre: new FormControl('', [Validators.required, Validators.maxLength(45)]),
		descripcion: new FormControl('', [Validators.required, Validators.maxLength(300)])
	});

	perfilDetalleForm = new FormGroup({
		numero: new FormControl('', [Validators.required, Validators.pattern('[0-9][0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?[0-9]?')]),
		archivo: new FormControl('')
	});

	nums = new MatTableDataSource([]);
	selections: SelectionModel<any>[] = [];
	profile: any = [];
	resultadosCSV: any = [];
	selected = 0;
	isLoadingResults = false;
	isDeleting = false;
	isGeneratingFile = false;
	profileDetailCount = 0;
	filename = "";
	displayedColumns = ["celular", "nombre", "usuario", "pais", "codigo_cliente", "codigo_pais", "tipo_linea", "correo", "accion"];


	constructor(private services: ServicesService, private dialogRef: MatDialogRef<ingresoPerfil>, @Inject(MAT_DIALOG_DATA) public data: any, private localSave: LocalSaveService, private snackBar: MatSnackBar, private papa: Papa, private dialog: MatDialog) {
		this.profile = this.data.profile;
		this.selections[0] = new SelectionModel<any>(true, []);
		if (typeof (this.profile) != 'undefined') {
			this.perfilActivo = this.profile.codigo_perfil;
			this.nombreActivo = this.profile.nombre;
			this.perfilForm.patchValue(this.profile);
		}
	}

	reload() {
		if (!this.selections[this.paginator.pageIndex]) {
			this.selections[this.paginator.pageIndex] = new SelectionModel<any>(true, []);
		}
		this.isLoadingResults = true;
		this.services.getProfileDetail({ codigo_perfil: this.perfilActivo, numero: this.filter_search, indice: this.paginator.pageIndex }).subscribe(data => {
			this.nums = new MatTableDataSource(data);
			this.services.getProfileDetailCount({ codigo_perfil: this.perfilActivo, numero: this.filter_search }).subscribe(data => {
				this.profileDetailCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
	}

	search() {
		if (this.perfilDetalleForm.value.numero) {
			this.filter_search = this.perfilDetalleForm.value.numero.toString();
		} else {
			this.filter_search = "";
		}
		if (this.paginator.pageIndex > 0) {
			this.paginator.firstPage();
		}
		this.reload();
	}

	inserted() {
		this.filter_search = "";
		if (this.paginator.pageIndex > 0) {
			this.paginator.firstPage();
		}
		this.reload();
	}

	tabsel(evt): void {
		this.selected = evt;
		if (this.selected == 1) {
			this.reload();
		}
	}

	guardarPerfil() {
		this.services.insertProfile(this.perfilForm.value).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.perfilActivo = resultado;
				this.nombreActivo = this.perfilForm.value.nombre;
				this.tabsel(1);
			} else {
				this.snackBar.open("Perfil ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		});
	}

	modificarPerfil() {
		this.services.updateProfile(this.perfilForm.value).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.perfilActivo = resultado;
				this.nombreActivo = this.perfilForm.value.nombre;
				this.tabsel(1);
			} else {
				this.snackBar.open("Perfil ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		});
	}

	async insertarPerfilDetalle(indice, CSV) {
		if (indice < CSV.length) {
			var profileDetail = { perfil: this.perfilActivo, numero: +CSV[indice][0] }
			if (profileDetail.numero < 0 || profileDetail.numero > 9999999999 || isNaN(profileDetail.numero)) {
				var tmp = { numero: CSV[indice][0], estado: "Error, solo se aceptan números positivos menores o iguales a 10 digitos." };
				this.resultadosCSV.push(tmp);
			} else if (profileDetail.numero == 0) {
			} else {
				await this.processInsert(profileDetail);
			}
			indice++;
			this.insertarPerfilDetalle(indice, CSV);
		} else {
			this.isGeneratingFile = false;
			var blob = new Blob(["\ufeff", this.papa.unparse(this.resultadosCSV)]);
			saveAs.saveAs(blob, "Resultado-" + this.filename);
			this.resultadosCSV = [];
			this.inserted();
		}
	}

	processInsert(profileDetail) {
		return new Promise((resolve, reject) => {
			this.services.insertProfileDetail(profileDetail).subscribe(data => {
				var resultado = data[0].resultado;
				if (resultado == -1) {
					var tmp = { numero: profileDetail.numero, estado: "Error, el número " + profileDetail.numero + " ya se encuentra en el perfil " + this.nombreActivo + "." };
					this.resultadosCSV.push(tmp);
					resolve();
				} else {
					this.services.redisInsertProfileNumber({key: 'profile:' + this.perfilActivo + ":members", member: profileDetail.numero}).subscribe(data =>{
						var tmp = { numero: profileDetail.numero, estado: "Se agregó el número " + profileDetail.numero + " al perfil " + this.nombreActivo + " correctamente." };
						this.resultadosCSV.push(tmp);
						resolve();
					});
					
				}
			});
		})
	}

	agregarPerfilDetalle() {
		let num = this.perfilDetalleForm.value.numero;
		var profileDetail = { perfil: this.perfilActivo, numero: num }
		this.services.insertProfileDetail(profileDetail).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado == -1) {
				this.snackBar.open("Error, el número " + num + " ya se encuentra en el perfil " + this.nombreActivo + ".", '', { duration: 3000 });
			} else{
				this.services.redisInsertProfileNumber({key: 'profile:' + this.perfilActivo + ":members", member: num}).subscribe(data =>{
					this.snackBar.open("Se agregó el número " + num + " al perfil " + this.nombreActivo + " correctamente.", '', { duration: 3000 });
					this.inserted();
				});
			}
		});
	}

	loadNumbers() {
		document.getElementById("fileNumbers").click();
	}

	onFileChangeNums(evt: any) {
		this.filename = evt.target.files[0].name;
		this.papa.parse(evt.target.files[0], {
			complete: (results) => {
				this.isGeneratingFile = true;
				this.insertarPerfilDetalle(0, results.data)
			}
		});
	}

	downloadCSV() {
		window.open('./assets/numeros.csv', '_blank');
	}

	async deleteNumbers() {
		let dialogRef = this.dialog.open(deleteElement, {
			disableClose: true,
			data: { user: this.nombreActivo }
		});
		dialogRef.afterClosed().subscribe(async result => {
			if (result) {
				this.isDeleting = true;
				for (let selection of this.selections) {
					for (let s of selection.selected) {
						await this.processDelete(s);
					}
					selection.clear();
				}
				this.isDeleting = false;
				this.inserted();
			}
		});
	}

	processDelete(u) {
		return new Promise((resolve, reject) => {
			this.services.deleteProfileNumber({ usuario: u, perfil: this.perfilActivo }).subscribe(data => {
				this.services.getNumber({usuario: u}).subscribe(data =>{
					this.services.redisDeleteProfileNumber({ member: data[0].celular, key: 'profile:' + this.perfilActivo + ":members" }).subscribe(data =>{
						resolve();
					});
				});
			});
		});
	}

	checkLength(): boolean {
		for (let selection of this.selections) {
			if (selection.selected.length > 0) {
				return true;
			}
		}
		return false;
	}

	isAllSelected() {
		const numSelected = this.selections[this.paginator.pageIndex].selected.length;
		const numRows = this.nums.data.length;
		return numSelected === numRows;
	}

	masterToggle() {
		this.isAllSelected() ?
			this.selections[this.paginator.pageIndex].clear() :
			this.nums.data.forEach(row => this.selections[this.paginator.pageIndex].select(row.codigo_usuario));
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	getErrorMessage(element) {
		if (element.hasError('required')) {
			return "Campo requerido";
		} else if (element.hasError('maxlength')) {
			return "Longitud maxima de " + element.errors.maxlength.requiredLength + " caracteres";
		} else if (element.hasError('pattern')) {
			return "Solo número enteros y positivos menores o iguales a 10 digitos";
		}
	}
}