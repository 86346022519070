import { Component, OnInit, Inject } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { MatSnackBar } from '@angular/material';
import { Md5 } from 'ts-md5/dist/md5';
import { LocalSaveService } from '../local-save.service';
import { ServicesService } from '../services.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ValidatemenusService } from '../validatemenus.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  wheight: string;
  user = { USUARIO: '', PASSWORD: '' };
  constructor(private validatemenu: ValidatemenusService, private http: HttpClient, private localSave: LocalSaveService, private services: ServicesService, private router: Router, private snackBar: MatSnackBar, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.wheight = window.innerHeight + 'px';
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.autoriza) {
        this.services.loginAVI(params).subscribe(data => {
          if (data.status) {
            this.localSave.set('user', data.user);
            this.validatemenu.setpermisos(data.permisos);
            this.validatemenu.firststate();
          }
        });
      }
    });
  }

  login() {
    if (this.user.USUARIO == 'admin@isoft-ste.com' && this.user.PASSWORD == '123') {
      const user = {
        
        rol: 0,
        nombre: 'admin',
        usuario: 'admin',
        objeto: 'admin',
        codigo_usuario: 0
      };
      this.localSave.set('user', user);
      this.validatemenu.setpermisos([
        'mnoBotscrear',
        'mnoBotseditararbol',
        'mnoBotseditar',
        'mnoBotseliminar',
        'mnoBroadcastcopiar',
        'mnoBroadcastcrear',
        'mnoBroadcasteditar',
        'mnoBroadcasteliminar',
        'mnoCasosabrir',
        'mnoComandoscrear',
        'mnoComandoseditar',
        'mnoComandoseliminar',
        'mnoNodoscrear',
        'mnoNodoseliminar',
        'mnoNodoseditar',
        'mnoPerfilescrear',
        'mnoPerfileseditar',
        'mnoPerfileseliminar',
        'mnoAdministradorareas',
        'mnoBots',
        'mnoBroadcast',
        'mnoCasos',
        'mnoComandos',
        'mnoGestionplanesrequisitos',
        'mnoGestorarchivos',
        'mnoNodos',
        'mnoPerfiles',
        'mnoSegmentos'
      ]);
      // this.router.navigate(['home/back-office']);
      this.validatemenu.firststate();
    } else {
      this.snackBar.openFromComponent(errorLoginComponent, { duration: 2000, });
    }
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.login();
    }
  }

}

@Component({
  selector: 'snack-bar-component-example-snack',
  templateUrl: 'snack-bar-component-example-snack.html',
  styles: [`.error {font-family: Roboto,"Helvetica Neue",sans-serif}`],
})
export class errorLoginComponent { }
