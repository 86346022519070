import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, PageEvent, MatTableDataSource, MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ServicesService } from '../../services.service';
import { ComponentPageTitle } from '../../page-title/page-title';
import { deleteElement } from '../../delete/eliminar-element.component';
import { HomeComponent } from '../../home/home.component';
import { LocalSaveService } from '../../local-save.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-requisitos',
  templateUrl: './requisitos.component.html',
  styleUrls: ['./requisitos.component.css']
})
export class RequisitosComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  requisitos = new MatTableDataSource([]);
  requisitosCount;
  isLoadingResults = true;
  filter_search = "";
  displayedColumns: string[] = ['nombre', 'fuente', 'pais', 'accion'];
  country;
  
  constructor(private componentPageTitle: ComponentPageTitle, private localSave: LocalSaveService, private services: ServicesService, private dialog: MatDialog, private snackBar: MatSnackBar, private home: HomeComponent) { }

  ngOnInit() {
    this.country = this.localSave.get("country");
    this.home.change.subscribe(pais =>{ 
      this.country = pais;
      this.reload();
    });
		this.reload();
  }

  reload(){
		var filters = [this.filter_search, this.paginator.pageIndex];
		this.isLoadingResults = true;
		this.services.getRequirements({filters: filters, codigo_pais: this.country}).subscribe(data => {
			this.requisitos = new MatTableDataSource(data);
			this.services.getRequirementsCount({filters: filters, codigo_pais: this.country}).subscribe(data => {
				this.requisitosCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
  }

  search() {
    this.filter_search = this.filter_search.trim();
    if(this.paginator.pageIndex > 0){
			this.paginator.firstPage();
		}
		this.reload();
	}

  insert(){
    const dialogRef = this.dialog.open(ingresoRequisito, {
      width: '700px',
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.search();
    });
  }

  modify(requisito){
    const dialogRef = this.dialog.open(ingresoRequisito, {
      width: '700px',
      disableClose: true,
      data: {requisito}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.search();
    });
  }

  delete(requisito){
    let dialogRef = this.dialog.open(deleteElement, {
      disableClose: true,
      data: {requisito}
      });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.services.deleteRequirementCountry({codigo: requisito.codigo_requisito, pais: requisito.codigo_pais}).subscribe(data => {
          this.search();
        });
      }
    });
  }
}

@Component({
	selector: 'ingreso-requisitos',
  templateUrl: 'ingreso-requisitos.html',
  styles: ['.mat-form-field { width: 60%; } ::ng-deep .mat-simple-snackbar{ font-size: 15pt; align-content: center; }']
})

export class ingresoRequisito {

  requisitoActivo = -1;
  nombreActivo = "";
  paises;
  requisito;
  isEditing = false;

  formRequisito = new FormGroup({
    codigo_requisito: new FormControl(''),
		nombre: new FormControl('', [Validators.required, Validators.maxLength(250)]),
    fuente: new FormControl('', [Validators.required, Validators.maxLength(250)]),
    paises: new FormControl('', Validators.required)
	});

  constructor(private services: ServicesService, private dialogRef: MatDialogRef<ingresoRequisito>, @Inject(MAT_DIALOG_DATA) public data: any, private localSave: LocalSaveService, private snackBar: MatSnackBar, private dialog: MatDialog) {
    this.requisito = this.data.requisito;
    if(typeof(this.requisito) != 'undefined'){
      this.formRequisito.patchValue(this.requisito);
      this.formRequisito.controls.paises.setValue([this.requisito.codigo_pais]);
      this.requisitoActivo = this.requisito.codigo_requisito;
      this.nombreActivo = this.requisito.nombre;
      this.isEditing = true;
    }
    this.reload();
  }

  reload(){
    this.services.getCountries().subscribe(data =>{
      this.paises = data;
    });
  }

  saveRequirement(){
    var params = this.formRequisito.value;
    this.services.insertRequirement(params).subscribe(data =>{
      var resultado = data[0].resultado;
			if (resultado != -1) {
				this.requisitoActivo = resultado;
        this.nombreActivo = params.nombre;
        this.services.insertRequirementCountry({requisito: resultado, paises: params.paises}).subscribe(data =>{
          this.snackBar.open("Requisito ingresado exitosamente", '', { duration: 3000 });
          this.dialogRef.close();
        });
			}
			else{
				this.snackBar.open("Requisito ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
    });
  }

  modifyRequirement(){
    this.services.updateRequirement(this.formRequisito.value).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.requisitoActivo = resultado;
        this.nombreActivo = this.formRequisito.value.nombre;
        this.snackBar.open("Requisito actualizado correctamente", '', { duration: 3000 });
				this.dialogRef.close();
			}
			else{
				this.snackBar.open("Requisito ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		});
  }

  onNoClick(): void {
		this.dialogRef.close();
	}

  getErrorMessage(element){
		if(element.hasError('required')){
			return "Campo requerido";
		}else if(element.hasError('maxlength')){
			return "Longitud maxima de " + element.errors.maxlength.requiredLength + " caracteres";
		}
	}

}

