import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material';
import * as emoji from 'node-emoji';
import { EmojisComponent } from '../emojis/emojis.component';

@Component({
  selector: 'app-justificacion',
  templateUrl: './justificacion.component.html',
  styleUrls: ['./justificacion.component.css']
})
export class JustificacionComponent implements OnInit {

  justificarForm = new FormGroup({
    justificacion: new FormControl('', Validators.required)
  });
  constructor( public dialog: MatDialog,private dialogref:MatDialogRef<JustificacionComponent>) { }

  ngOnInit() {
  }


	openDialog(): void {
		const dialogRef = this.dialog.open(EmojisComponent, {
			data: {}
		});

		dialogRef.afterClosed().subscribe(result => {
			let auxvalue = this.justificarForm.controls.justificacion.value;
			if(auxvalue){
        this.justificarForm.controls.justificacion.setValue(auxvalue + emoji.emojify(result.colons));
      }else{
        this.justificarForm.controls.justificacion.setValue(emoji.emojify(result.colons));
      }

		});
  }
  
  close(){
    let res = this.justificarForm.controls.justificacion.value;
    res = emoji.unemojify(res);
    this.dialogref.close(res);
  }

}
