import { Component, Inject } from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import { FormControl, Validators, FormGroup } from '@angular/forms';

@Component({
	selector: 'eliminar-elemento',
	templateUrl: './eliminar-elemento.html',
	styles: ['.full{	background-color: #00A9E0;	color: white;	border-radius: 10em;} .empty{	color:#00A9E0;border-radius: 10em; } .mensaje{ font-family:Roboto,"Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif} .mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;}']
})
export class deleteElement {

    button = 'Eliminar';
    configuracion;
    case;
    file;
    transaction;
    node;
    profile;
    user;
    broadcast;
    bot;
    justificacion;
    requisito;
    familia;
    segmento;
	eliminarForm = new FormGroup({
		justificacion : new FormControl('', Validators.required)
    });
    
	constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
        this.configuracion = this.data.configuracion;
        this.familia = this.data.familia;
        this.file = this.data.file;
        this.transaction = this.data.transaction;
        if(typeof(this.file) != 'undefined'){
            this.button = 'Seleccionar';
        }
        this.node = this.data.node;
        this.user = this.data.user;
        this.profile = this.data.profile;
        this.bot = this.data.bot;
        this.broadcast = this.data.broadcast;
        this.requisito = this.data.requisito;
        this.case = this.data.case;
        if(typeof(this.case) != 'undefined'){
            this.button = 'Aceptar';
        }
        this.segmento = this.data.segmento;
	}

}