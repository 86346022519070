import { Component, OnInit, HostListener, ViewChildren, QueryList, Inject } from '@angular/core';
import { MatDialog, MatTableDataSource, MatPaginator, MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { ComponentPageTitle } from '../page-title/page-title';
import { LocalSaveService } from '../local-save.service';
import { ServicesService } from '../services.service';
import { deleteElement } from '../delete/eliminar-element.component';
import { JustificacionComponent } from '../justificacion/justificacion.component';

@Component({
  selector: 'app-back-office',
  templateUrl: './back-office.component.html',
  styleUrls: ['./back-office.component.css']
})
export class BackOfficeComponent implements OnInit {

	displayedColumns = ['caso', 'fecha_creacion', 'bot', 'descripcion', 'estado', 'comentario', 'accion'];
	@ViewChildren(MatPaginator) paginators : QueryList<MatPaginator>;
	cases = new MatTableDataSource([]);
	cases_pending = [];
	total_pending = 0;
	cases_autorized = [];
	total_autorized = 0;
	cases_rejected= [];
	total_rejected = 0;
	filter_search = "";
	filter_estado = "";
	isLoading = false;
	casesCount:any = 0;
	isMobile: boolean = false;
	width;

	@HostListener('window:resize', ['$event'])
	onResize(event) {
		this.width = event.target.innerWidth;
		if(this.width < 599){
      this.isMobile = true;
    }else{
      this.isMobile = false;
		}
		this.search();
	}

  constructor(private componentPageTitle: ComponentPageTitle, private localSave: LocalSaveService, private dialog: MatDialog, private services: ServicesService, private snackBar: MatSnackBar) { 
		this.width = window.innerWidth;
		if(this.width < 599){
      this.isMobile = true;
    }else{
      this.isMobile = false;
		}
	}

  ngOnInit() {
    this.componentPageTitle.title = 'Casos';
	}
	
	ngAfterViewInit(){
		setTimeout(() => {
			this.reload();
		});
	}

	loadEstadosbutton(estado)
	{
		if(this.filter_estado == estado)
			this.filter_estado = ""
		else
			this.filter_estado = estado;
		this.reload();
	}

  reload(){
		if(!this.isMobile){
			this.isLoading = true;
			var filters = [this.filter_search, this.paginators.first.pageIndex, this.filter_estado];
			this.services.getCases(filters).subscribe(data => {
				this.cases = new MatTableDataSource(data);
				this.services.getCasesCount(filters).subscribe(data => {
					this.casesCount = data[0];
					this.isLoading = false;
				});
			});
		}else{
			var index_pending = 0;
			var index_autorized = 0;
			var index_rejected = 0;
			var paginators_array = this.paginators.toArray();
			if(typeof(paginators_array[0]) != 'undefined'){
				index_pending = paginators_array[0].pageIndex;
			}
			if(typeof(paginators_array[1]) != 'undefined'){
				index_autorized = paginators_array[1].pageIndex;
			}
			if(typeof(paginators_array[2]) != 'undefined'){
				index_rejected = paginators_array[2].pageIndex;
			}
			
			this.isLoading = true;
			var filters_pending = [this.filter_search, index_pending, "pendiente"];
			var filters_approved = [this.filter_search, index_autorized, "autorizado"];
			var filters_rejected = [this.filter_search, index_rejected, "rechazado"];
			this.services.getCases(filters_pending).subscribe(data => {
				this.cases_pending = data;
				this.services.getCasesCount(filters_pending).subscribe(data => {
					this.total_pending = data[0].total;
					this.services.getCases(filters_approved).subscribe(data => {
						this.cases_autorized = data;
						this.services.getCasesCount(filters_approved).subscribe(data => {
							this.total_autorized = data[0].total;
							this.services.getCases(filters_rejected).subscribe(data => {
								this.cases_rejected = data;
								this.services.getCasesCount(filters_rejected).subscribe(data => {
									this.total_rejected = data[0].total;
									this.services.getCasesCount(filters).subscribe(data => {
										this.casesCount = data[0];
										this.isLoading = false;
									});
								});
							});
						});
					});
				});
			});
		}
	}

	search(){
		this.filter_search = this.filter_search.trim();
		this.filter_estado = this.filter_estado.trim();
		this.paginators.forEach(paginator =>{
			if(paginator.pageIndex > 0){
				paginator.firstPage();
			}
		});
		this.reload();
	}

	openCase(element){
			this.services.getTramite(element).subscribe(data =>{
				if(data[0].tramite == -1){
					this.services.setTramite({codigo_caso: element.codigo_caso, tramite: 1}).subscribe(data =>{
						let dialogRef = this.dialog.open(verBackOffice, {
							disableClose: true,
							width: '1000px',
							height: '90%',
							data: {case: element}
						});
						dialogRef.afterClosed().subscribe(result => {
							this.services.setTramite({codigo_caso: element.codigo_caso, tramite: -1}).subscribe(data =>{
								this.reload();
							})
						});
					});
				}else{
					this.snackBar.open("El caso "+element.codigo_caso+" esta siendo trabajado por el usuario " + data[0].tramite, '', { duration: 3000 });	
				}
			});
	}

}

@Component({
	selector: 'ver-back-office',
	templateUrl: 'ver-back-office.html',
	styles: ['.mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;}']
})


export class verBackOffice {

	case;
	case_data;
	keys = Object.keys;
	telefono;

	constructor(private dialogRef: MatDialogRef<verBackOffice>, @Inject(MAT_DIALOG_DATA) private data: any, private localSave: LocalSaveService, private snackBar: MatSnackBar, private services: ServicesService, private dialog: MatDialog) {
		this.case = this.data.case;
		this.case_data = JSON.parse(this.case.datos);
		this.telefono = this.case_data.telefono;
		delete this.case_data.telefono
	}

	getIcon(element){
			
			var filename = element.substring(element.lastIndexOf('/')+1);
			var fileExt = filename.split('.').pop();
			if(fileExt.toLocaleLowerCase() === "pdf"){
				return "../../assets/pdf.png";
			}else if(fileExt.toLocaleLowerCase() === "doc" || fileExt.toLocaleLowerCase() === "docx"){
				return "../../assets/doc.png";
			}else if(fileExt.toLocaleLowerCase() === "jpg"){
				return "../../assets/jpg.png";
			}else if(fileExt.toLocaleLowerCase() === "png"){
				return "../../assets/png.png";
			}else if(fileExt.toLocaleLowerCase() === "ppt"){
				return "../../assets/ppt.png";
			}else if(fileExt.toLocaleLowerCase() === "txt"){
				return "../../assets/txt.png";
			}else if(fileExt.toLocaleLowerCase() === "xls" || fileExt.toLocaleLowerCase() === "xlsx"){
				return "../../assets/xls.png";
			}else if(fileExt.toLocaleLowerCase() === "zip" || fileExt.toLocaleLowerCase() === "rar"){
				return "../../assets/zip.png";
			}else if(fileExt.toLocaleLowerCase() === "mp4"){
				return "../../assets/mp4.png";
			}else if(fileExt.toLocaleLowerCase() === "avi"){
				return "../../assets/avi.png";
			}else if(fileExt.toLocaleLowerCase() === "json"){
				return "../../assets/json.png";
			}else if(fileExt.toLocaleLowerCase() === "mp3"){
				return "../../assets/mp3.png";
			}else{
				return "../../assets/file.png";
			}
  }

	checkIfUrl(value){
		//let regexp =  /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
		let regexp =  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=. a-zA-ZÀ-ÿ\u00f1\u00d1]+$/;
		if(regexp.test(value)){
			return true;
		}
		return false;
	}

	openLink(link){
		window.open(link, '_blank');
	}

	updateCase(element, estado_caso){
		let dialogRef = this.dialog.open(JustificacionComponent, {
			width:'650px',
			disableClose: true,
			data: {case: element}
			});
		dialogRef.afterClosed().subscribe(result => {
			if(result){
				console.log(result);
				this.services.updateCase({codigo_caso: element.codigo_caso, comentario: result, estado_caso: estado_caso}).subscribe(data =>{
					this.services.sendResponseBackOffice({caso: element.codigo_caso}).subscribe(data =>{
						console.log(data);
					})
					this.dialogRef.close();
				});
			}
		});
	}

	close(){
		this.dialogRef.close();
	}

}
