import { Component, OnInit, Inject, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from "@angular/router";
import { MatPaginator, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSortModule, MatSort, MatSnackBar, MatChipInputEvent } from '@angular/material';
import { FormGroup, FormControl, Validators, ReactiveFormsModule } from '@angular/forms';
import {PageEvent} from '@angular/material';
import { Papa } from 'ngx-papaparse';
import { Observable } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {EmptyObservable} from 'rxjs/observable/EmptyObservable';
import { deleteElement } from '../delete/eliminar-element.component';
import { LocalSaveService } from '../local-save.service';
import { ServicesService } from '../services.service';
import { ComponentPageTitle } from '../page-title/page-title';
import { FileExplorerComponent } from '../file-explorer/file-explorer.component';
import { WebServiceComponent } from './web-service/web-service.component';
import { EmojiSearch } from '@ctrl/ngx-emoji-mart';
import { EmojisComponent } from '../emojis/emojis.component';
import * as emoji from 'node-emoji';
import { ValidatemenusService } from '../validatemenus.service';

@Component({
	selector: 'app-comandos',
	templateUrl: './comandos.component.html',
	styleUrls: ['./comandos.component.css']
})
export class ComandosComponent implements OnInit {

	estados:any=[];
	@ViewChild(MatPaginator) paginator : MatPaginator;
	displayedColumns = ['nombre', 'descripcion', 'tipo_consumo', 'fecha_creacion', 'accion'];
	comandos = new MatTableDataSource([]);
	filter_search = "";
	isLoadingResults = false;
	comandosCount = 0;

	constructor(
		private componentPageTitle: ComponentPageTitle, 
		private router: Router, 
		private localSave: LocalSaveService, 
		private dialog: MatDialog, 
		private services: ServicesService,
		private validatemenu: ValidatemenusService,
		) { }

	ngOnInit() {
		this.componentPageTitle.title = 'Comandos';
		this.reload();
	}

	fnvalidatemenu(menu) {
		return this.validatemenu.permiso(menu);
	}

	reload(){
		var filters = [this.filter_search,this.paginator.pageIndex];
		this.isLoadingResults = true;
		this.services.getComandos(filters).subscribe(data => {
			this.comandos = new MatTableDataSource(data);
			this.services.getComandosCount(filters).subscribe(data => {
				this.comandosCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
	}

	search() {
		this.filter_search = this.filter_search.trim();
		if(this.paginator.pageIndex > 0){
			this.paginator.firstPage();
		}
		this.reload();
	}

	ingresoComando() {
		let dialogRef = this.dialog.open(ingresoComandos, {
			disableClose: true,
			width: '90%',
			height: '90%',
			data: {}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	modificar(comando)
	{
		let dialogRef = this.dialog.open(ingresoComandos, {
			disableClose: true,
			width: '90%',
			height: '90%',
			data: {comando}
		});

		dialogRef.afterClosed().subscribe(result => {
			this.search();
		});
	}

	eliminar(node){
		let dialogRef = this.dialog.open(deleteElement, {
			disableClose: true,
			data: {node}
		});
		dialogRef.afterClosed().subscribe(result => {
			if(result){
				this.services.deleteNode({codigo: node.codigo_nodo}).subscribe(data => {
					this.search();
				});
			}
		});
	}
}

@Component({
	selector: 'ingreso-comandos',
	templateUrl: 'ingreso-comandos.html',
	styles: ['::ng-deep.mat-tab-body-wrapper {height:100%;} .example-table-container {position: relative;max-height: 400px;overflow: auto;}.example-loading-shade {position: absolute;top: 0;left: 0;bottom: 56px;right: 0;background: rgba(0, 0, 0, 0.15);z-index: 1;display: flex;align-items: center;justify-content: center;}.example-container {position: relative;}.mat-header-cell{text-align: center;}.mat-cell{text-align: center;}.mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;} .mat-form-field {	margin:5%;} .addButton{	float: bottom;	margin-top: -1%;	margin-bottom: 1%;	background-color:#00A9E0;} ::ng-deep .mat-sort-header-container { display:flex; justify-content:center;} th.mat-sort-header-sorted { color: black; text-align: center;} .mat-paginator-container{justify-content: center} table { width: 100%; height: 100%;} .example-loading-shade { position: absolute; top: 0; left: 0; bottom: 56px; right: 0; background: rgba(0, 0, 0, 0.15); z-index: 1; display: flex; align-items: center; justify-content: center; }']
})

export class ingresoComandos{


	@ViewChild(WebServiceComponent) webservice : WebServiceComponent;
	@ViewChild(MatPaginator) paginator : MatPaginator;
	comandoActivo = -1;
	nombreActivo = "";
	isEditable:boolean = false;
	isSalida:boolean = false;
	comandoForm = new FormGroup({
		codigo_nodo: new FormControl(''),
		nombre: new FormControl('', Validators.required),
		descripcion: new FormControl('', Validators.required),
		response_var: new FormControl(''),
		tipo_consumo: new FormControl('0'),
		url: new FormControl({value: null, disabled: true}),
		accion: new FormControl(''),
		descripcion_caso: new FormControl({value: '', disabled: true}),
		tipo_peticion: new FormControl(),
		tipo_nodo: new FormControl(''),
		codigo_tipo_nodo: new FormControl(''),
		im: new FormControl('1'),
		bot: new FormControl(),
		is_correct: new FormControl(false),
		nfrases: new FormControl()
	});

	perfilDetalleForm = new FormGroup({
		frase: new FormControl('', Validators.required)
	});
	
	actionForm = new FormGroup({
		parametro: new FormControl('', Validators.required),
		codigo_parametro: new FormControl(''),
		direccion_parametro: new FormControl(''),
		tipo: new FormControl('', Validators.required),
		tipo_accion: new FormControl('', Validators.required),
		posicion: new FormControl('', Validators.required),
		orden: new FormControl(''),
		tipo_insercion: new FormControl('', Validators.required)
	});

	typeData = new FormGroup({
		codigo: new FormControl(''),
		nombre: new FormControl(''),
	});

	profileColumns = ["nombre", "descripcion", "asociado"];
	actionColumns = ["no", "tipo", "tipo_accion","parametro", "accion"];

	wasModified;
	bigger;
	perfilesData;
	perfiles = new MatTableDataSource([]);
	acciones = new MatTableDataSource([]);
	comando:any=[];
	resultadosCSV:any=[];
	selected = 0;
	filter_search = "";
	isLoadingResults: boolean = false;
	comandoDetailCount = 0;
	tiposAccion:any = [];
	isMiddle: boolean = false;
	isMultimedia: boolean = false;
	isTexto: boolean = false;
	board;
	highlightedRows = [];
	frases: any[] = [];

	visible = true;
	selectable = true;
	removable = true;
	addOnBlur = true;
	nativeemojis = new MatTableDataSource([]); 

	readonly separatorKeysCodes: number[] = [ENTER, COMMA];
	
	constructor(private emojiSearch: EmojiSearch,private dialogRef: MatDialogRef<ingresoComandos>, @Inject(MAT_DIALOG_DATA) public data: any, private localSave: LocalSaveService, private snackBar: MatSnackBar, public services: ServicesService, private papa: Papa, private dialog: MatDialog) {
		
		this.comando = this.data.comando;
		this.board = this.data.board;
		this.actionForm.controls.posicion.disable();
		if(typeof(this.comando) != 'undefined'){
			var tmpFrases = this.comando.frases.substring(12,this.comando.frases.length-1).split(",");
			if(tmpFrases.length > 0 && tmpFrases[0].length > 0)
				this.frases = tmpFrases;
			this.comandoForm.patchValue(this.comando);
			this.isEditable = true;
			this.comandoActivo = this.comando.codigo_nodo;
			this.nombreActivo = this.comando.nombre;
			if(this.comandoForm.controls.url.value != null && this.comandoForm.controls.tipo_peticion.value != null && this.comandoForm.controls.response_var.value != null){
				this.comandoForm.controls.is_correct.setValue(true);
			}
			if(this.comandoForm.value.tipo_consumo === '2'){
				this.services.getInputsWebService({codigo: this.comandoActivo}).subscribe(data =>{
					if(data[0].parametro === 'descripcion'){
						this.comandoForm.controls.descripcion_caso.setValue(data[0].valor);
					}
				});
			}
			this.reloadActions();
		}
		this.services.getTypeByName({nombre:"comando"}).subscribe(data =>{
			this.comandoForm.controls.codigo_tipo_nodo.setValue(data[0].codigo_tipo_nodo);
			this.comandoForm.controls.tipo_nodo.setValue(data[0].nombre);
		});
	}

	updateConsume(evt){
		if(evt.value === "2"){
			this.comandoForm.controls.descripcion_caso.enable();
		}else{
			this.comandoForm.controls.descripcion_caso.disable();
		}
	}

	updateActionType(evt){
		if(evt.value.toLowerCase() === "entrada"){
			this.isSalida = false;
			this.isMultimedia = false;
			this.isTexto = false;
			this.actionForm.controls.tipo_accion.setValue("");
			this.services.getInputTypes({im: 1}).subscribe(data =>{
				this.tiposAccion = data;
			});
		}else if(evt.value.toLowerCase() === "salida"){
			this.isSalida = true;
			this.actionForm.controls.tipo_accion.setValue("");
			this.services.getOutputTypes({im: 1}).subscribe(data =>{
				this.tiposAccion = data;
			});
		}else if(evt.value.toLowerCase() === "multimedia" && this.isSalida){
			this.isMultimedia = true;
		}else if(evt.value.toLowerCase() === "texto" && this.isSalida){
			this.isTexto = true;
		}

		if(evt.value.toLowerCase() !== "texto" && this.isTexto){
			this.isTexto = false;
		}
		if(this.isMultimedia && evt.value.toLowerCase() !== "multimedia"){
			this.isMultimedia = false;
		}
	}

	addAction(){
		console.log(this.acciones.data);
		let accion = this.actionForm.value;
		let accionnative = JSON.parse(JSON.stringify(accion));
		accionnative.parametro = emoji.emojify(accionnative.parametro);
		if(accion.tipo === "entrada" && typeof(this.acciones.data.find(x => x.parametro == accion.parametro)) !== 'undefined'){
			this.snackBar.open("No se puede tener una accion de tipo entrada con el mismo parametro", '', { duration: 3000 });
		}else if(accion.tipo === "entrada" && !/^([a-zA-Z_][a-zA-Z_0-9]*)$/.test(accion.parametro)){
			this.snackBar.open("No se puede tener una accion de tipo entrada que su parametro no sea un identificador", '', { duration: 3000 });
		}else{
			if(accion.tipo_insercion === "inicio"){
				this.acciones.data.splice(0,0,accion);
				this.nativeemojis.data.splice(0,0,accionnative);
				this.acciones = new MatTableDataSource(this.acciones.data);
				this.nativeemojis = new MatTableDataSource(this.nativeemojis.data);
				console.log(this.acciones.data);
			} else if(accion.tipo_insercion === "medio"){
				this.acciones.data.splice(accion.posicion-1,0,accion);
				this.nativeemojis.data.splice(accion.posicion-1,0,accionnative);
				this.acciones = new MatTableDataSource(this.acciones.data);
				this.nativeemojis = new MatTableDataSource(this.nativeemojis.data);
				console.log(this.acciones.data);
			} else if(accion.tipo_insercion === "final"){
				this.acciones.data.push(accion);
				this.nativeemojis.data.push(accionnative);
				this.acciones = new MatTableDataSource(this.acciones.data);
				this.nativeemojis = new MatTableDataSource(this.nativeemojis.data);
				console.log(this.acciones.data);
			}
			this.actionForm.reset();
			console.log(this.acciones.data);
		}
	}

	openExplorer(){
		let dialogRef = this.dialog.open(FileExplorerComponent, {
			panelClass: 'my-panel',
			width: '100%',
			height: '90%',
			maxWidth: '100vw',
			disableClose: true,
			data: {isCommand: true}
		});
		dialogRef.afterClosed().subscribe( result => {
			if(result){
				const selectedFile = dialogRef.componentInstance.selectedFile;
				this.actionForm.controls.codigo_parametro.setValue(selectedFile.codigo_archivo);
				this.actionForm.controls.parametro.setValue(selectedFile.nombre);
				this.actionForm.controls.direccion_parametro.setValue(selectedFile.direccion);
			}
		});
	}

	updatePosition(evt){
		this.actionForm.controls.tipo_insercion.setValue(evt.value);
		if(this.actionForm.value.tipo_insercion === "medio"){
			this.isMiddle = true;
			this.actionForm.controls.posicion.enable();
		}else if(this.isMiddle && this.actionForm.value.tipo_insercion !== "medio"){
			this.isMiddle = false;
			this.actionForm.controls.posicion.disable();
		}
	}

	updateProfile(index)
	{
		this.paginator = index;
		//console.log("ACTUALIZA PERFIL ");
		this.reloadProfiles();
	}

	reloadProfiles() {
		this.isLoadingResults = true;
		var params = {codigo_nodo:this.comandoActivo, pageIndex: this.paginator.pageIndex*3, filter_search: this.filter_search};
		this.services.getProfilesbyNode(params).subscribe(data => {
			this.perfilesData = data;
			this.perfiles = new MatTableDataSource(this.perfilesData);
			this.services.getProfilesbyNodeCount(params).subscribe(data => {
				this.comandoDetailCount = data[0].total;
			});
			this.isLoadingResults = false;
		});
	}

	reloadActions(){
		this.isLoadingResults = true;
		var params = {codigo: this.comandoActivo};
		this.services.getOutputsComando(params).subscribe(outputs =>{
			this.services.getInputsComando(params).subscribe(inputs => {
				this.acciones = new MatTableDataSource(outputs.concat(inputs));
				this.acciones.data.sort((a,b)=>{return a.orden - b.orden});
				this.bigger = this.acciones.data.length - 1;
				this.nativeemojis = new MatTableDataSource(JSON.parse(JSON.stringify(this.acciones.data)));
				this.loadnativeemojis();
				this.isLoadingResults = false;
			});
		});
	}
	
	loadnativeemojis():void {
		this.nativeemojis.data.map((chip,index)=>{
			chip.parametro = emoji.emojify(chip.parametro);
		});
	}

	emoji(): void {
		const dialogRef = this.dialog.open(EmojisComponent, {
			data: {}
		});

		dialogRef.afterClosed().subscribe(result => {
			if(result){
				let auxvalue = this.actionForm.controls.parametro.value;
				this.actionForm.controls.parametro.setValue(auxvalue + result.colons);
			}
		});
	}

	editAction(event, index){
		let below = this.returnBelow();
		let above = this.returnAbove();
		if(this.highlightedRows.length != 0){
			if(event == "before"){
				if(above == -1){
					this.snackBar.open("No se puede desplazar hacia arriba", '', { duration: 3000 });
				}else{
					let element = this.acciones.data[above];
					this.acciones.data.splice(above, 1);
					this.nativeemojis.data.splice(above, 1);
					this.acciones.data.splice(below-1,0,element);
					this.nativeemojis.data.splice(below-1,0,element);
					this.acciones = new MatTableDataSource(this.acciones.data);
					this.nativeemojis = new MatTableDataSource(this.nativeemojis.data);
					this.loadnativeemojis();
				}
			}else if(event == "after"){
				if(below == this.acciones.data.length){
					this.snackBar.open("No se puede desplazar hacia abajo", '', { duration: 3000 });
				}else{
					let element = this.acciones.data[below];
					this.acciones.data.splice(below, 1);
					this.nativeemojis.data.splice(below, 1);
					this.acciones.data.splice(above+1,0,element);
					this.nativeemojis.data.splice(above+1,0,element);
					this.acciones = new MatTableDataSource(this.acciones.data);
					this.nativeemojis = new MatTableDataSource(this.nativeemojis.data);
					this.loadnativeemojis();
				}
			}
		}else{
			this.snackBar.open("Debe seleccionar al menos una accion", '', { duration: 3000 });
		}
	}

	async continuarWS()
	{
		this.isLoadingResults = true;
		await this.webservice.saveVars();
		this.isLoadingResults = false;
		//this.tabsel(3);
		this.dialogRef.close(true);
	}

	deleteAction(index){
		console.log(this.acciones.data);
		this.acciones.data.splice(index, 1);
		this.acciones = new MatTableDataSource(this.acciones.data);
		this.nativeemojis.data.splice(index, 1);
		this.nativeemojis = new MatTableDataSource(this.nativeemojis.data);
		this.bigger = this.acciones.data.length - 1;
		this.loadnativeemojis();
		console.log(this.acciones.data);
	}

	returnBelow(){
		let first = this.nativeemojis.data.indexOf(this.highlightedRows[0]);
		let last = this.nativeemojis.data.indexOf(this.highlightedRows[this.highlightedRows.length - 1]);
		if( first > last){
			return first + 1;
		}else{
			return last + 1;
		}
	}

	returnAbove(){
		let first = this.nativeemojis.data.indexOf(this.highlightedRows[0]);
		let last = this.nativeemojis.data.indexOf(this.highlightedRows[this.highlightedRows.length - 1]);
		if( first > last){
			return last - 1;
		}else{
			return first - 1;
		}
	}

	selectActionRow(element){
		if(this.nativeemojis.data.length !== 1){
			let currentIndex = this.nativeemojis.data.indexOf(element);
			let above = this.nativeemojis.data[currentIndex+1];
			let below = this.nativeemojis.data[currentIndex-1];
			if(this.highlightedRows.includes(element)){
				if(this.highlightedRows.includes(above) && this.highlightedRows.includes(below)){
					this.highlightedRows = [];
				}else{
					this.highlightedRows.splice(this.highlightedRows.indexOf(element), 1);
				}
			}else{
				if(this.highlightedRows.includes(above)){
					this.highlightedRows.push(element);
				}
				else if(this.highlightedRows.includes(below)){
					this.highlightedRows.push(element);
				}else{
					this.highlightedRows = [];
					this.highlightedRows.push(element);
				}
			}
		}
	}

	tabsel(evt): void {
		this.selected = evt;
		if(this.selected === 3){
			this.reloadProfiles();
		}
	}

	asociarPerfil(perfil) {
		var params = {id_nodo: this.comandoActivo, id_perfil: perfil.codigo_perfil}
		this.services.insertNodeProfile(params).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.reloadProfiles();
			}
		});	
	}

	search() {
		this.filter_search = this.filter_search.trim();
		if(this.paginator.pageIndex > 0){
			this.paginator.firstPage();
		}
		this.reloadProfiles();
	}

	modifyCommand(){
		this.services.updateNode(this.comandoForm.value).subscribe(data => {
			this.wasModified = data[0].resultado;
			if (this.wasModified != -1) {
				this.nombreActivo = this.comandoForm.value.nombre;
				if(this.comandoForm.value.tipo_consumo === '0'){
					this.services.deleteInputsHeaders({codigo:this.comandoActivo}).subscribe(data =>{

					});
				}else if(this.comandoForm.value.tipo_consumo === '2'){
					this.services.deleteInputsHeaders({codigo:this.comandoActivo}).subscribe(data =>{
						this.services.updateCommandBackOffice(this.comandoActivo, this.comandoForm.value.descripcion_caso);
					});
				}
				this.tabsel(1);
			}
			else{
				this.snackBar.open("Nodo ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		}); 
	}

	saveCommand(){
		this.services.insertNode(this.comandoForm.value).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.isEditable = true;
				this.comandoActivo = resultado;
				this.nombreActivo = this.comandoForm.value.nombre;
				if(this.comandoForm.value.tipo_consumo === '2'){
					this.services.updateCommandBackOffice(this.comandoActivo, this.comandoForm.value.descripcion_caso);
				}
				this.tabsel(1);
			}
			else{
				this.snackBar.open("Nodo ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		}); 
	}

	saveActions(){
		var params = {nodo: this.comandoActivo, acciones: this.acciones.data};
		this.services.insertActions(params).subscribe(data =>{
			if(this.comandoForm.value.tipo_consumo === '1' || this.comandoForm.value.tipo_consumo === '3'){
				this.webservice.reload();
				this.tabsel(2);
			}else{
				this.dialogRef.close(true);
			}
		});
	}
	
	guardarFrases(){
		var params = {frases: this.frases, nodo: this.comandoActivo};
		this.services.updateNodoFrases(params).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.snackBar.open("El Nodo "+this.nombreActivo+" se guardó correctamente", '', { duration: 3000 });
				this.dialogRef.close(true);
			}
		});
		this.dialogRef.close(true);
	}

	add(event: MatChipInputEvent): void {
		const input = event.input;
		const value = event.value;

		// Add our frase
		if ((value || '').trim()) {
			this.frases.push(value.trim());
		}

		// Reset the input value
		if (input) {
			input.value = '';
		}
	}
	
	remove(frase: any): void {
		const index = this.frases.indexOf(frase);

		if (index >= 0) {
			this.frases.splice(index, 1);
		}
	}

	cancelWebService(){
		this.onNoClick(true);
	}

	onNoClick(event): void {
		this.dialogRef.close(event);
	}

}