import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, PageEvent, MatTableDataSource, MatDialog} from '@angular/material';
import { ServicesService } from '../../services.service';
import { ComponentPageTitle } from '../../page-title/page-title';
import { HomeComponent } from '../../home/home.component';
import { LocalSaveService } from '../../local-save.service';

@Component({
  selector: 'app-planes',
  templateUrl: './planes.component.html',
  styleUrls: ['./planes.component.css']
})
export class PlansComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  plans = new MatTableDataSource([]);
  plansCount;
  isLoadingResults = true;
  filter_search = "";
  displayedColumns: string[] = ['nombre', 'pais'];
  country;
  
  constructor(private localSave: LocalSaveService, private services: ServicesService, private dialog: MatDialog, private home: HomeComponent) { }

  ngOnInit() {
    this.country = this.localSave.get("country");
    this.home.change.subscribe(pais =>{ 
      this.country = pais;
      this.reload();
    });
		this.reload();
  }

  reload(){
		var filters = [this.filter_search, this.paginator.pageIndex];
		this.isLoadingResults = true;
		this.services.getPlans({filters: filters, codigo_pais: this.country}).subscribe(data => {
			this.plans = new MatTableDataSource(data);
			this.services.getPlansCount({filters: filters, codigo_pais: this.country}).subscribe(data => {
				this.plansCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
  }

  search() {
    this.filter_search = this.filter_search.trim();
    if(this.paginator.pageIndex > 0){
			this.paginator.firstPage();
		}
		this.reload();
	}

}