import { Component, OnInit, Input } from "@angular/core";
import { LocalSaveService } from "../../local-save.service";
import { MatDialog, MatDialogRef, MatTableDataSource, MatSnackBar, MatOptionSelectionChange } from "@angular/material";
import { ServicesService } from "../../services.service";
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http'
import { FormGroup, FormControl, Validators } from "@angular/forms";


@Component({
	selector: 'app-web-service',
	templateUrl: './web-service.component.html',
	styles: ['::ng-deep.mat-tab-body-wrapper {height:100%;} .example-table-container {position: relative; overflow: auto;}.example-loading-shade {position: absolute;top: 0;left: 0;bottom: 56px;right: 0;background: rgba(0, 0, 0, 0.15);z-index: 1;display: flex;align-items: center;justify-content: center;}.example-container {position: relative;}.mat-header-cell{text-align: center;}.mat-cell{text-align: center;}.mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;} .mat-form-field {	margin:5%;} .addButton{	float: bottom;	margin-top: -1%;	margin-bottom: 1%;	background-color:#00A9E0;} ::ng-deep .mat-sort-header-container { display:flex; justify-content:center;} th.mat-sort-header-sorted { color: black; text-align: center;} .mat-paginator-container{justify-content: center} table { width: 100%; height: 100%;} .example-loading-shade { position: absolute; top: 0; left: 0; bottom: 56px; right: 0; background: rgba(0, 0, 0, 0.15); z-index: 1; align-items: center; justify-content: center; }']
})
export class WebServiceComponent implements OnInit {

	@Input() comandoActivo:number;
	@Input() url;
	@Input() accion;
	@Input() response_var;
	@Input() tipo_consumo;
	@Input() tipo_peticion;
	@Input() is_correct;
	body;
	selected = 0;
	isLoadingResults: boolean = false;
	variablesColumns = ["nombre", "tipo", "valor", "accion"];
	variables = new MatTableDataSource([]);
	headerColumns = ["llave", "valor", "accion"];
	headers = new MatTableDataSource([]);

	variableForm = new FormGroup({
		parametro: new FormControl('', Validators.required),
		tipo_accion: new FormControl('', Validators.required)
	});

	webserviceForm = new FormGroup({
		tipo_peticion: new FormControl('', Validators.required),
		url: new FormControl('', Validators.required),
		response_var: new FormControl('', Validators.required),
		accion: new FormControl(''),
	});

	headerForm = new FormGroup({
		llave: new FormControl('', Validators.required),
		valor: new FormControl('', Validators.required),
	});

	constructor(private snackBar: MatSnackBar, private dialogRef: MatDialogRef<WebServiceComponent>, private localSave: LocalSaveService, private dialog: MatDialog, private services: ServicesService, private http: HttpClient){
	}

	ngOnInit() {
		this.reload();
	}


	tabsel(evt): void{
		this.selected = evt;
	}

	reload(){

		console.log(this.tipo_consumo.value);
		if(this.tipo_consumo.value == 3)
			console.log("DESHABILITA");
		else
			console.log("HABILITA")

		this.isLoadingResults = true;
		this.webserviceForm.controls.url.setValue(this.url.value);
		this.webserviceForm.controls.tipo_peticion.setValue(this.tipo_peticion.value);
		this.webserviceForm.controls.response_var.setValue(this.response_var.value);
		this.webserviceForm.controls.accion.setValue(this.accion);
		this.services.getInputsComando({codigo: this.comandoActivo}).subscribe(actions =>{
			this.services.getInputsWebService({codigo: this.comandoActivo}).subscribe(variables =>{
				this.variables = new MatTableDataSource(actions.concat(variables));
				this.services.getHeaders({codigo: this.comandoActivo}).subscribe(headers =>{
					//console.log(headers);
					if(headers.length == 0 && this.tipo_consumo.value != 2)
					{
						//console.log("ENTRA AL WS AUNQUE SEA CASOS")
						let header = this.headerForm.value;
						this.services.insertHeader({llave:'Content-Type', valor:'application/json', codigo_comando: this.comandoActivo}).subscribe(data =>{
							header.codigo_encabezado = data[0].resultado;
							header.llave = 'Content-Type';
							header.valor = 'application/json';
							this.headers.data.push(header);
							this.headers = new MatTableDataSource(this.headers.data);
						});
					}
					else
						this.headers = new MatTableDataSource(headers);
						
					this.isLoadingResults = false;
				});
			});
		});
	}

	async testWebService(){
		this.tabsel(2);
		await this.consumeWebService();
		if(this.is_correct.value){
			this.saveVars();
		}
	}

	async saveVars()
	{
		for(let v of this.variables.data){
			this.services.updateInput(v).subscribe(data =>{
			});
		}
		console.log(this.webserviceForm.value.accion);
		this.services.updateCommandUrl({url:this.webserviceForm.value.url, tipo_peticion: this.webserviceForm.value.tipo_peticion, tipo_consumo: this.tipo_consumo.value, codigo: this.comandoActivo, response_var: this.webserviceForm.value.response_var, accion: this.webserviceForm.value.accion}).subscribe(data =>{
			this.url.setValue(this.webserviceForm.value.url);//aquiles
		});
	}

	parseUrl(url, params, action)
	{
		console.log(url);
		console.log(params);
		console.log(action);
		var strParams = '';
		params.forEach(param => {
		strParams = '/' + strParams + param.parametro + '/' + param.valor;
		});
		//console.log(strParams);
		var _url = "";
		if(action.length > 0)
			_url = url + strParams + '/' + action;
		else
			_url = url + strParams;
		//console.log(_url);
		return _url;
	}

	consumeWebService() {
		return new Promise((resolve, reject) => {
			let webService = this.webserviceForm.value;
			let temp_headers = this.headers.data;
			let headers: HttpHeaders = new HttpHeaders();
			//headers = headers.append("Content-Type", "application/json");
			var list_headers = {};

			for (let h of temp_headers) {
				headers = headers.append(h.llave, h.valor);
				list_headers[h.llave] = h.valor;
			}
			
			let temp_params = this.variables.data;
			//console.log(temp_params);
			var income = {};
			var list_income = [];
			for (let p of temp_params) {
				if (p.valor !== null) {
					if (p.tipo_accion === "texto") {
						//console.log(p.valor.substr(1, p.valor.length - 2));
						income[p.parametro] = p.valor.substr(1, p.valor.length - 2); 
						list_income.push({parametro: p.parametro, valor: p.valor.substr(1, p.valor.length - 2)});
					} else {
						//console.log(p.valor);
						income[p.parametro] = p.valor;
						list_income.push({parametro: p.parametro, valor: p.valor});
					}
				}
				else
				{
					this.snackBar.open("Ingresa valores en todas las variables para probar el servicio", '', { duration: 3000 });
					return;
				}
			}
			let params = {};
			if(this.tipo_consumo.value != 3)
			{
				if (webService.tipo_peticion === "get") {
					//AGREGAR HEADERS
					console.log(webService.url);
					console.log(income);
					this.services.testGetWs([webService.url, income, list_headers]).subscribe(data =>{
						console.log(data);
						this.is_correct.setValue(true);
						this.body = JSON.stringify(data);
						resolve();
					}, error => {
						this.body = JSON.stringify(error);
						this.is_correct.setValue(false);
						reject();
					});
				} else
				{
					//console.log(webService.tipo_peticion.toUpperCase());
					//console.log(webService.url);
					//console.log(income);
					//console.log("ENTRO AL WS STANDARD " +webService.tipo_peticion.toUpperCase());
					//var tUrl = this.parseUrl(webService.url, list_income, this.webserviceForm.value.accion);
					//console.log(tUrl);
					this.services.testCustomWs([webService.url, list_headers, webService.tipo_peticion.toUpperCase(), income]).subscribe(data => {
						console.log(data);
						this.is_correct.setValue(true);
						this.body = JSON.stringify(data);
						resolve();
					}, error => {
						this.body = JSON.stringify(error);
						this.is_correct.setValue(false);
						reject();
					});
				}
			}
			else // ws custom
			{
				console.log("ENTRO AL WS CUSTOM");
				var tUrl = this.parseUrl(webService.url, list_income, this.webserviceForm.value.accion);
				console.log(tUrl);
				this.services.testCustomWs([tUrl, list_headers, webService.tipo_peticion.toUpperCase()]).subscribe(data => {
					console.log(data);
					this.is_correct.setValue(true);
					this.body = JSON.stringify(data);
					resolve();
				}, error => {
					this.body = JSON.stringify(error);
					this.is_correct.setValue(false);
					reject();
				});
			}
			
		});
	}

	addVariable(){
		let variable = this.variableForm.value;
		console.log(variable);
		if(typeof(this.variables.data.find(x => x.parametro == variable.parametro)) !== 'undefined'){
			this.snackBar.open("Variable ya esta incluida", '', { duration: 3000 });
		}else if(!/^([a-zA-Z_]\w*)$/.test(variable.parametro)){
			this.snackBar.open("El nombre de la variable no puede tener espacios", '', { duration: 3000 });
		}else{
			this.services.insertInput({tipo_accion:variable.tipo_accion, parametro: variable.parametro, codigo_comando: this.comandoActivo}).subscribe(data =>{
				variable.codigo_entrada = data[0].resultado;
				this.variables.data.push(variable);
				this.variables = new MatTableDataSource(this.variables.data);
				this.variableForm.reset();
			});
			
		}	
	}

	deleteVariable(index, element){
		this.services.deleteInput(element).subscribe(data =>{
			this.variables.data.splice(index, 1);
			this.variables = new MatTableDataSource(this.variables.data);
		});
	}

	addHeader(){
		let header = this.headerForm.value;
		if(typeof(this.headers.data.find(x => x.llave == header.llave)) !== 'undefined'){
			this.snackBar.open("Llave ya esta incluida", '', { duration: 3000 });
		}else{
			this.services.insertHeader({llave:header.llave, valor:header.valor, codigo_comando: this.comandoActivo}).subscribe(data =>{
				header.codigo_encabezado = data[0].resultado;
				this.headers.data.push(header);
				this.headers = new MatTableDataSource(this.headers.data);
				this.headerForm.reset();
			});
		}	
	}

	deleteHeader(index, element){
		this.services.deleteHeader(element).subscribe(data =>{
			this.headers.data.splice(index, 1);
			this.headers = new MatTableDataSource(this.headers.data);
		});
	}

	getErrorType(element){
		if(element.valor){
			if(element.tipo_accion === "entero"){
				if(!/^(\d+\.?\d*|\.\d+)$/.test(element.valor) && !/^(_[a-zA-Z_]\w*)$/.test(element.valor)){
					return "Ingrese un numero o una variable";
				}
			}else if(element.tipo_accion === "texto"){
				if(!/"([^']*)"/.test(element.valor) && !/^(_[a-zA-Z_]\w*)$/.test(element.valor)){
					return 'Ingrese un texto dentro de ""  o una variable';
				}
			}
		}
	}
}
