import { Component, OnInit, Inject, ViewChild, Injectable } from '@angular/core';
import { FileElement } from './structures/element';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Observable } from 'rxjs/Observable';
import { ServicesService } from '../services.service';
import { MatSnackBar } from '@angular/material';
import * as AWS from 'aws-sdk/global';
import * as S3 from 'aws-sdk/clients/s3';
import { Subject } from 'rxjs';
import { ComponentPageTitle } from '../page-title/page-title';
import { v4 } from 'uuid';
import { FormControl, Validators } from '@angular/forms';
import { deleteElement } from '../delete/eliminar-element.component';

@Component({
  selector: 'file-explorer',
  templateUrl: './file-explorer.component.html',
  styleUrls: ['./file-explorer.component.css']
})

export class FileExplorerComponent implements OnInit {

  isLoadingResults = false;
  shorten:Boolean = true;
  isCommand: boolean = false;
  selectedFile: FileElement;
  currentFolder: FileElement[] = [];
  files = new Map<string, FileElement>();
  path: string;
  currentRoot: FileElement;
  currentPath: string;
  canNavigateUp: Boolean = false;
  filter_search = "";
  im = 1;
  creds = {
    access_key:'', 
    secret_key:'', 
    bucket:'',
    region:''
  };

  constructor(private dialogRef: MatDialogRef<FileExplorerComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private componentPageTitle: ComponentPageTitle, private dialog: MatDialog, private services: ServicesService, private snackBar: MatSnackBar) {
    this.isCommand = this.data.isCommand;
    this.services.getBucket().subscribe(data =>{
      this.creds.access_key = data[0].access_key;
      this.creds.secret_key = data[0].secret_key;
      this.creds.bucket = data[0].bucket;
      this.creds.region = data[0].region;
    });
  }

  ngOnInit() {
    this.isLoadingResults = true;
    this.services.getRoot().subscribe(data =>{
      if(data.length == 0){
        this.services.insertFile({es_folder: true, nombre: "$root", im : 1}).subscribe();
      }
      this.services.getFiles({im: 1, todos: 1}).subscribe(data =>{
        for(let element of data){
          element.shorten = true;
          this.files.set(element.codigo_archivo.toString(), element);
        }
        if(!this.isCommand){
          this.componentPageTitle.title = 'Archivos';
        }
        this.isLoadingResults = false;
        this.reload();
      });
    });
  }

  selectFile(element: FileElement){
    let dialogRef = this.dialog.open(deleteElement, {
			disableClose: true,
			data: {file: element, transaction: "select"}
		});
		dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.selectedFile = element;
        this.dialogRef.close(true);
      }
		});
    
  }

  deleteElement(element: FileElement) {
    let dialogRef = this.dialog.open(deleteElement, {
      disableClose: true,
      data: {file: element, transaction: "delete"}
    });
    dialogRef.afterClosed().subscribe(async result => {
      if(result){
        this.isLoadingResults = true;
        if(element.es_folder){
          await this.deleteChilds(element);
        }
        await this.deleteFile(element);
        this.isLoadingResults = false;
        this.reload();
        this.snackBar.open("Archivo "+ element.nombre +" eliminado exitosamente", '', { duration: 3000 });
      }
		});
  }

  deleteChilds(element: FileElement){
    return new Promise((resolve, reject) =>{
      this.files.forEach(async e =>{
        if(e.padre === element.codigo_archivo){
          if(e.es_folder){
            await this.deleteChilds(e);
          }
          await this.deleteFile(e);
        }
      });
      resolve();
    });
  }

  deleteFile(element){
    return new Promise((resolve, reject) =>{
      this.services.deleteFile({codigo: element.codigo_archivo}).subscribe(data => {
        this.files.delete(element.codigo_archivo.toString());
        resolve();
      });
    });
  }

  navigate(element: FileElement) {
    if (element.es_folder) {
      this.currentRoot = element;
      this.reload();
      this.currentPath = this.pushToPath(element);
      this.canNavigateUp = true;
    }else{
      window.open(element.direccion, '_blank');
    }
  }

  navigateUp() {
    if (this.currentRoot && this.currentRoot.padre === 0) {
      this.currentRoot = null;
      this.canNavigateUp = false;
      this.reload();
    } else {
      this.currentRoot = this.files.get(this.currentRoot.padre.toString());
      this.reload();
    }
    this.currentPath = this.popFromPath(this.currentPath);
  }

  creatFolder() {
    let dialogRef = this.dialog.open(FolderDialogComponent, {
      disableClose: true,
      width: '30%',
      height: '33%',
      data: {folder : -1}
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.addFolder({ es_folder: true, nombre: res, padre: this.currentRoot ? this.currentRoot.codigo_archivo : 0, im : 1, shorten: true});
      }
    });
  }

  cancel(){
    this.dialogRef.close(false);
  }

  addFolder(fileElement: FileElement){
      this.services.insertFile(fileElement).subscribe(data => {
        if(data[0].codigo != -1){
          fileElement.codigo_archivo = data[0].codigo;
          this.files.set(fileElement.codigo_archivo.toString(), this.clone(fileElement));
        }else{
          this.snackBar.open("Carpeta " + fileElement.nombre +" ya existente, ingrese otro nombre", '', { duration: 3000 });
        }
        this.reload();
      });
  }

  uploadFiles(){
    let dialogRef = this.dialog.open(FormUploadComponent, {
      disableClose: true,
      width: '50%',
      height: '50%',
      data: {
        currentRoot: this.currentRoot,
        files: this.files,
        creds: this.creds
      }
    });
    dialogRef.afterClosed().subscribe(res => {
      this.reload();
    });
  }

  renameFolder(element: FileElement) {
    let dialogRef = this.dialog.open(FolderDialogComponent, {
      disableClose: true,
      width: '30%',
      height: '33%',
      data: {folder : element.codigo_archivo}
    });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.update(element.codigo_archivo, res, element.padre);
      }
    });
  }

  update(element: number, name: string, parent: number){
    let temp = this.files.get(element.toString());
    name = name + (temp.extension ? temp.extension : "");
    this.services.updateFile({codigo: element, nombre:name, padre: parent}).subscribe(data =>{
      if(data[0].codigo != -1){
        temp.nombre = name;
        this.files.set(element.toString(), temp);
        this.snackBar.open("Nombre actualizado exitosamente", '', { duration: 3000 });
      }else{
        this.snackBar.open("Nombre " + name +" ya existente, ingrese otro nombre", '', { duration: 3000 });
      }
      this.reload();
    });
  }

  moveElement(element: FileElement, moveTo: number) {
    let puede_moverse = true;
    this.files.forEach(e =>{
      if(e.padre === moveTo){
        if(e.nombre === element.nombre){
          puede_moverse = false;
        }
      }
    });
    if(puede_moverse){
      element.padre = moveTo;
      this.files.set(element.codigo_archivo.toString(), element);
      this.services.updateFile({codigo: element.codigo_archivo, padre_nuevo:moveTo}).subscribe(data =>{
        this.reload();
      });
    }else{
      this.snackBar.open("No se pueve mover " + element.nombre +" ya que existe un archivo/folder con el mismo nombre en la carpeta destino", '', { duration: 3000 });
    }
  }

  openMenu(event: MouseEvent, viewChild: MatMenuTrigger) {
    event.preventDefault();
    viewChild.openMenu();
  }

  reload() {
    let root = this.currentRoot ? this.currentRoot.codigo_archivo : 0;
    this.currentFolder = [];
    this.files.forEach(element => {
      if (element.padre === root) {
        if(element.es_folder){
          this.currentFolder.unshift(this.clone(element));
        }else{
          this.currentFolder.push(this.clone(element));
        }
        this.currentFolder.sort((a: FileElement,b: FileElement) =>{
          var A = a.nombre.toLocaleUpperCase();
          var B = b.nombre.toLocaleUpperCase();
          if(a.es_folder && b.es_folder){
            if(A<B){
              return -1;
            }
            if(A>B){
              return 1;
            }
          }
          if(!a.es_folder && !b.es_folder){
            if(A<B){
              return -1;
            }
            if(A>B){
              return 1;
            }
          }
          return 0;
        });
      }
    });
  }

  clone(element: FileElement) {
    return JSON.parse(JSON.stringify(element));
  }

  pushToPath(element: FileElement) {
    let path = '';
    while(true){
      if(element.padre === 0){
        path = 'Archivos/' + element.nombre + '/' + path;
        break;
      }
      path = element.nombre + '/' + path;
      element = this.files.get(element.padre.toString());
    }
    return path;
  }

  popFromPath(path: string) {
    let p = path ? path : '';
    let split = p.split('/');
    split.splice(split.length - 2, 1);
    p = split.join('/');
    return p;
  }

  hasFolders(element: FileElement){
    let result = false;
    let num = 0;
    this.files.forEach(e => {
      if(e.es_folder){
        result = true;
        num++;
      }
    });
    if(result){
      if(element.es_folder && num == 1){
        result = false;
      }
    }
    return result;
  }

  search(){
    if(this.filter_search != ""){
      this.currentFolder = [];
      this.files.forEach(element => {
        if (element.nombre.toLocaleLowerCase().includes(this.filter_search.toLocaleLowerCase())) {
          this.currentFolder.push(this.clone(element));
        }
      });
    }else{
      this.currentRoot = null;
      this.reload();
    }
  }

  getExtension(element: FileElement){
    if(element.es_folder){
      return "../../assets/folder.png";
    }else if(element.extension.toLocaleLowerCase() === ".pdf"){
      return "../../assets/pdf.png";
    }else if(element.extension.toLocaleLowerCase() === ".doc" || element.extension.toLocaleLowerCase() === ".docx"){
      return "../../assets/doc.png";
    }else if(element.extension.toLocaleLowerCase() === ".jpg"){
      return "../../assets/jpg.png";
    }else if(element.extension.toLocaleLowerCase() === ".png"){
      return "../../assets/png.png";
    }else if(element.extension.toLocaleLowerCase() === ".ppt"){
      return "../../assets/ppt.png";
    }else if(element.extension.toLocaleLowerCase() === ".txt"){
      return "../../assets/txt.png";
    }else if(element.extension.toLocaleLowerCase() === ".xls" || element.extension.toLocaleLowerCase() === ".xlsx"){
      return "../../assets/xls.png";
    }else if(element.extension.toLocaleLowerCase() === ".zip" || element.extension.toLocaleLowerCase() === ".rar"){
      return "../../assets/zip.png";
    }else if(element.extension.toLocaleLowerCase() === ".mp4"){
      return "../../assets/mp4.png";
    }else if(element.extension.toLocaleLowerCase() === ".avi"){
      return "../../assets/avi.png";
    }else if(element.extension.toLocaleLowerCase() === ".json"){
      return "../../assets/json.png";
    }else if(element.extension.toLocaleLowerCase() === ".mp3"){
      return "../../assets/mp3.png";
    }else{
      return "../../assets/file.png";
    }
  }
}

@Component({
  selector: 'folder-explorer',
  templateUrl: './folder-explorer.html'
})
export class FolderDialogComponent implements OnInit {

  folderName = new FormControl('', [Validators.required, Validators.maxLength(100)]);
  folder; 

  constructor(public dialogRef: MatDialogRef<FolderDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.folder = this.data.folder;
  }

  getErrorMessage(element){
		if(element.hasError('required')){
			return "Campo requerido";
		}else if(element.hasError('maxlength')){
			return "Longitud maxima de " + element.errors.maxlength.requiredLength + " caracteres";
		}
	}
  
  ngOnInit() {}
}

@Component({
  selector: 'form-upload',
  templateUrl: './form-upload.html',
  styles: ['.mat-raised-button[disabled] {box-shadow: none;color: white !important;border: transparent !important;background-color: #00A9E0;background-color: rgba(0,0,0,.12) !important;} .add-files-btn {  float: right;}:host {  height: 100%;  display: flex;  flex: 1;  flex-direction: column;}.actions {  justify-content: flex-end;}.container {  height: 100%;}']
})
export class FormUploadComponent{
 
  @ViewChild('file') file;
  newFiles: Set<File> = new Set();
  objectFiles = new Map<FileElement, any>();
  fileErrors = [];
  currentRoot;
  files;
  creds = {
    access_key:'', 
    secret_key:'', 
    bucket:'',
    region:''
  };

  constructor(private dialogRef: MatDialogRef<FormUploadComponent>, @Inject(MAT_DIALOG_DATA)  private data : any, private services: ServicesService, private snackBar: MatSnackBar) { 
    this.currentRoot = this.data.currentRoot;
    this.files = this.data.files;
    this.creds = this.data.creds;
  }

  progress;
  canBeClosed = true;
  primaryButtonText = 'Cargar';
  uploading = false;
  uploadSuccessful = false;

  addFiles() {
     this.file.nativeElement.click();
  }

  onFilesAdded() {
    const files: { [key: string]: File } = this.file.nativeElement.files;
    for (let key in files) {
      if (!isNaN(parseInt(key))) {
        if (files[key].size == 0){
          this.fileErrors.push("El archivo "+ files[key].name +" se ignoro, por que no tiene contenido");
        }else if(files[key].name.length > 100){
          this.fileErrors.push("El archivo "+ files[key].name +" se ignoro, por que el nombre no puede tener mas de 100 caracteres");
        }else{
          this.newFiles.add(files[key]);
        }
      }
    }
  }

  closeDialog() {
    if (this.uploadSuccessful) {
      return this.dialogRef.close();
    }

    this.uploading = true;

    this.progress = this.uploadFiles(this.newFiles, this.currentRoot);
    for (const key in this.progress) {
      this.progress[key].progress.subscribe();
    }

    let allProgressObservables = [];
    for (let key in this.progress) {
      allProgressObservables.push(this.progress[key].progress);
    }

    this.primaryButtonText = 'Completar';
    this.canBeClosed = false;

    forkJoin(allProgressObservables).subscribe(end => {
      this.canBeClosed = true;
      this.uploadSuccessful = true;
      this.uploading = false;
    });

  }

  uploadFiles(files: Set<File>, currentRoot: FileElement) : { [key: string]: Observable<number> } {
    const status = {};

    const bucket = new S3({
      accessKeyId: this.creds.access_key,
      secretAccessKey: this.creds.secret_key,
      region: this.creds.region
    });

    files.forEach(file => {
      const progress = new Subject<number>();
      if(file){
        let extension = '.' + file.name.split('.').pop();
        let id = v4();
        let address = 'https://s3.amazonaws.com/casebots2/bots/' + id + extension;
        const fileElement : FileElement = { nombre: file.name, es_folder: false, padre: currentRoot ? currentRoot.codigo_archivo : 0, extension: extension,  direccion: address, llave: id, tipo: file.type, im: 1, shorten: true};
        this.services.insertFile(fileElement).subscribe(data_services => {
          if(data_services[0].codigo != -1){
            fileElement.codigo_archivo = data_services[0].codigo;
            this.files.set(fileElement.codigo_archivo.toString(), this.clone(fileElement));
            let params = {
              Bucket: this.creds.bucket,
              Key: id + extension,
              Body: file,
              ContentType: file.type,
              ServerSideEncryption: 'AES256',
              ContentDisposition: 'inline'
            };
            let object = bucket.putObject(params).on("httpUploadProgress", evt =>{
              const percentDone = Math.round((100 * evt.loaded) / evt.total);
              progress.next(percentDone);
            }).send((err, data) => {
              if(err){
                this.services.deleteFileError({codigo: fileElement.codigo_archivo}).subscribe(data =>{
                  this.fileErrors.push("El archivo "+ fileElement.nombre +" no se ha cargado al servidor debido a un error o a que ha sido cancelado por el usuario.");
                  this.files.delete(fileElement.codigo_archivo.toString());
                });
              }
              progress.complete();
            });
            this.objectFiles.set(fileElement, object);
          }else{
            progress.next(100);
            progress.complete();
            this.snackBar.open("Archivo "+ name +" ya existente, ingrese otro nombre", '', { duration: 3000 });
          }
        });
      }
      status[file.name] = {
        progress: progress.asObservable()
      };
    });
    return status;
  }
  
  clone(element: FileElement) {
    return JSON.parse(JSON.stringify(element));
  }

  close(){
    let aborted:boolean = false;
    this.objectFiles.forEach(value =>{
      aborted = true;
      value.request.abort();
    });
    if(!aborted){
      this.dialogRef.close();
    }
  }

}
