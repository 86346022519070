import { Component, Output, Input } from 'rete';
import { textSocket } from '../structures/sockets';

export class SelectionComponent extends Component {

  constructor() {
    super('seleccion');
  }

  builder(node) {
    node.addInput(new Input('entrada', 'Entrada', textSocket, true));
    let temp = "";
    for(let i = 0; i<node.data.outputs.length;i++){
        temp += node.data.outputs[i] + '|';
    }
    node.addOutput(new Output('salida', temp.substring(0, temp.length - 1),textSocket, false));
  }

  worker(node, inputs, outputs) {

  }
}
