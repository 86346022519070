import { Component, Output } from 'rete';
import { textSocket } from '../structures/sockets';

export class StartComponent extends Component {

  constructor() {
    super('inicio');
  }

  builder(node) {
    node.addOutput(new Output('salida', 'Salida', textSocket, false));
  }

  worker(node, inputs, outputs) {

  }
}
