import { Component, Output, Input } from 'rete';
import { textSocket } from '../structures/sockets';

export class DpiComponent extends Component {

  constructor() {
    super('dpi');
  }

  builder(node) {
    node.addInput(new Input('entrada', 'Entrada', textSocket, true));
    node.addOutput(new Output('salida', 'Salida',textSocket, false));
  }

  worker(node, inputs, outputs) {

  }
}
