import { Component, Output, Input } from 'rete';
import { textSocket } from '../structures/sockets';

export class CommandComponent extends Component {

  constructor() {
    super('comando');
  }

  builder(node) {
    node.addInput(new Input('entrada', 'Entrada', textSocket, true));
    node.addOutput(new Output('salida1', 'Salida', textSocket, false));
  }

  worker(node, inputs, outputs) {
  }
}
