import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-emojis',
  templateUrl: './emojis.component.html',
  styleUrls: ['./emojis.component.css']
})
export class EmojisComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<EmojisComponent>) { }

  ngOnInit() {
  }

  addemoji(event){
  	this.dialogRef.close(event.emoji);
  }

}
