import { NgModule } from '@angular/core';
import { MatAutocompleteModule, MatNativeDateModule,MatButtonModule,MatToolbarModule,MatTableModule,
		 MatIconModule,MatSidenavModule,MatGridListModule,
		 MatCardModule,MatPaginatorModule,MatFormFieldModule,
		 MatInputModule,MatDialogModule,MatListModule,MatSelectModule,MatDatepickerModule,MatTabsModule,
     MatProgressSpinnerModule,MatProgressBarModule,MatMenuModule,MatSlideToggleModule,MatSnackBarModule,MatExpansionModule,MatTooltipModule, MatChipsModule, MatRadioModule } from '@angular/material';

@NgModule({
  imports: [MatRadioModule, MatNativeDateModule,MatButtonModule,MatToolbarModule,MatTableModule,
  			MatIconModule,MatSidenavModule,MatGridListModule,
  			MatCardModule,MatPaginatorModule,MatFormFieldModule,
  			MatInputModule,MatDialogModule,MatListModule,MatSelectModule,MatDatepickerModule,MatTabsModule,
        MatProgressSpinnerModule,MatProgressBarModule,MatMenuModule,MatSlideToggleModule,MatSnackBarModule,MatExpansionModule,MatTooltipModule, MatChipsModule],
  exports: [MatRadioModule, MatAutocompleteModule, MatButtonModule,MatToolbarModule,MatTableModule,
  			MatIconModule,MatSidenavModule,MatGridListModule,
  			MatCardModule,MatPaginatorModule,MatFormFieldModule,
  			MatInputModule,MatDialogModule,MatListModule,MatSelectModule,MatDatepickerModule,MatTabsModule,
        MatProgressSpinnerModule,MatProgressBarModule,MatMenuModule,MatSlideToggleModule,MatSnackBarModule,MatExpansionModule,MatTooltipModule, MatChipsModule],
})
export class MaterialModule { }