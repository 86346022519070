import { Component, OnInit, ViewChild, Inject, OnDestroy } from '@angular/core';
import { MatPaginator, MatSelect, MatTableDataSource, MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA, MatOption } from '@angular/material';
import { ServicesService } from '../../services.service';
import { ComponentPageTitle } from '../../page-title/page-title';
import { deleteElement } from '../../delete/eliminar-element.component';
import { HomeComponent } from '../../home/home.component';
import { LocalSaveService } from '../../local-save.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-familias',
  templateUrl: './familias.component.html',
  styleUrls: ['./familias.component.css']
})
export class FamiliesComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  familias = new MatTableDataSource([]);
  familiasCount;
  isLoadingResults = false;
  filter_search = "";
  displayedColumns: string[] = ['nombre', 'pais', 'total_planes', 'accion'];
  country;
  
  constructor(private localSave: LocalSaveService, private services: ServicesService, private dialog: MatDialog, private snackBar: MatSnackBar, private home: HomeComponent) { }

  ngOnInit() {
    this.country = this.localSave.get("country");
    this.home.change.subscribe(pais =>{ 
      this.country = pais;
      this.reload();
    });
		this.reload();
  }

  reload(){
		var filters = [this.filter_search, this.paginator.pageIndex];
		this.isLoadingResults = true;
		this.services.getFamilies({filters: filters, codigo_pais: this.country}).subscribe(data => {
			this.familias = new MatTableDataSource(data);
			this.services.getFamiliesCount({filters: filters, codigo_pais: this.country}).subscribe(data => {
				this.familiasCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
  }

  search() {
    this.filter_search = this.filter_search.trim();
    if(this.paginator.pageIndex > 0){
			this.paginator.firstPage();
		}
		this.reload();
	}

  insert(){
    const dialogRef = this.dialog.open(ingresoFamilia, {
      width: '700px',
      disableClose: true,
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.search();
    });
  }

  modify(familia){
    const dialogRef = this.dialog.open(ingresoFamilia, {
      width: '700px',
      disableClose: true,
      data: {familia}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.search();
    });
  }

  delete(familia){
    let dialogRef = this.dialog.open(deleteElement, {
      disableClose: true,
      data: {familia}
      });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.services.deleteFamily(familia).subscribe(data => {
          this.search();
        });
      }
    });
  }
}

@Component({
	selector: 'ingreso-familia',
  templateUrl: 'ingreso-familia.html',
  styles: ['.mat-form-field { width: 60%; } ::ng-deep .mat-simple-snackbar{ font-size: 15pt; align-content: center; } .example-loading-shade { position: absolute; top: 0; left: 0; bottom: 56px; right: 0; background: rgba(0, 0, 0, 0.15); z-index: 1; display: flex; align-items: center; justify-content: center; }']
})

export class ingresoFamilia {

  familiaActiva = -1;
  protected _onDestroy = new Subject<void>();
  nombreActivo = "";
  paises;
  planes = [];
  familia;
  allSelected = false;
  filtered: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  aux;
  isLoadingResults = false;

  formFamilia = new FormGroup({
    codigo_familia: new FormControl(''),
		nombre: new FormControl('', [Validators.required, Validators.maxLength(250)]),
    codigo_pais: new FormControl('', Validators.required),
    planes: new FormControl('', Validators.required),
    filter : new FormControl('', [])
	});

  constructor(private services: ServicesService, private dialogRef: MatDialogRef<ingresoFamilia>, @Inject(MAT_DIALOG_DATA) public data: any, private localSave: LocalSaveService, private snackBar: MatSnackBar, private dialog: MatDialog) {
    this.familia = this.data.familia;
    if(typeof(this.familia) != 'undefined'){
      this.isLoadingResults = true;
      this.formFamilia.patchValue(this.familia);
      this.familiaActiva = this.familia.codigo_familia;
      this.nombreActivo = this.familia.nombre;
      this.services.getPlansByCountry({codigo_pais: this.familia.codigo_pais}).subscribe(data =>{
        this.planes = data;
        this.aux = this.planes;
        this.filtered.next(this.planes.slice());
        this.formFamilia.controls.filter.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filter();
          })
        this.services.getPlansByFamily({codigo_familia: this.familia.codigo_familia}).subscribe(data =>{
          this.formFamilia.controls.planes.setValue(data.map(x => x.codigo_plan));
          this.isLoadingResults = false;
          if(this.formFamilia.value.planes.length === this.planes.length){
            this.allSelected = true;
          }
        });
      });
    }
    this.reload();
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  reload(){
    this.services.getCountries().subscribe(data =>{
      this.paises = data;
    });
  }

  updatePlans(evt){
    this.services.getPlansByCountry({codigo_pais: evt.value}).subscribe(data =>{
      this.planes = data;
      this.aux = this.aux = this.planes;
        this.filtered.next(this.planes.slice());
        this.formFamilia.controls.filter.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filter();
          })
    });
  }

  save(){
    var params = this.formFamilia.value;
    this.isLoadingResults = true;
    this.services.insertFamily(params).subscribe(data =>{
      var resultado = data[0].resultado;
			if (resultado != -1) {
				this.familiaActiva = resultado;
        this.nombreActivo = params.nombre;
        this.services.insertFamilyPlan({familia: resultado, planes: params.planes}).subscribe(data =>{
          this.isLoadingResults = false;
          this.snackBar.open("Familia ingresado exitosamente", '', { duration: 3000 });
          this.dialogRef.close();
        });
			}else{
        this.isLoadingResults = false;
				this.snackBar.open("Familia ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
    });
  }

  modify(){
    var params = this.formFamilia.value;
    this.isLoadingResults = true;
    this.services.updateFamily(params).subscribe(data => {
			var resultado = data[0].resultado;
			if (resultado != -1) {
				this.familiaActiva = resultado;
        this.nombreActivo = this.formFamilia.value.nombre;
        this.services.insertFamilyPlan({familia: resultado, planes: params.planes}).subscribe(data =>{
          this.isLoadingResults = false;
          this.snackBar.open("Familia actualizada correctamente", '', { duration: 3000 });
				  this.dialogRef.close();
        });
        
			}else{
        this.isLoadingResults = false;
				this.snackBar.open("Familia ya existente, ingrese otro nombre", '', { duration: 3000 });
			}
		});
  }

  toggle() {
    this.allSelected = !this.allSelected;
   
    this.filtered.subscribe(el => {
      this.aux = el;
      this.filtered.unsubscribe;
    }); 

    if (this.allSelected) {
      this.formFamilia.controls.planes.setValue(this.aux.map(x => x.codigo_plan));
    } else {
      this.formFamilia.controls.planes.setValue([]);
    }
  }

  filter() {
    if (!this.planes) return;
    let search = this.formFamilia.controls.filter.value;
    if (!search) {
      this.filtered.next(this.planes.slice());
    } else {
      search = search.toLowerCase();
      this.filtered.next(
        this.planes.filter(plan => plan.nombre.toLowerCase().indexOf(search) > -1)
      );
      this.aux = this.planes.filter(plan => plan.nombre.toLowerCase().indexOf(search) > -1)

    }
  }

  onNoClick(): void {
		this.dialogRef.close();
	}

  getErrorMessage(element){
		if(element.hasError('required')){
			return "Campo requerido";
		}else if(element.hasError('maxlength')){
			return "Longitud maxima de " + element.errors.maxlength.requiredLength + " caracteres";
		}
	} 

}

