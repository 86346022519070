import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { MatPaginator, MatTableDataSource, MatDialog, MatSnackBar, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ServicesService } from '../../services.service';
import { ComponentPageTitle } from '../../page-title/page-title';
import { deleteElement } from '../../delete/eliminar-element.component';
import { HomeComponent } from '../../home/home.component';
import { LocalSaveService } from '../../local-save.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-configuraciones',
  templateUrl: './configuraciones.component.html',
  styleUrls: ['./configuraciones.component.css']
})
export class ConfigurationComponent implements OnInit {

  @ViewChild(MatPaginator) paginator: MatPaginator;
  familias = new MatTableDataSource([]);
  familiasCount;
  isLoadingResults = false;
  filter_search = "";
  displayedColumns: string[] = ['pais', 'producto', 'canal', 'familia', 'scoring', 'segmento', 'accion'];
  country;
  
  constructor(private localSave: LocalSaveService, private services: ServicesService, private dialog: MatDialog, private snackBar: MatSnackBar, private home: HomeComponent) { }

  ngOnInit() {
    this.country = this.localSave.get("country");
    this.home.change.subscribe(pais =>{ 
      this.country = pais;
      this.reload();
    });
		this.reload();
  }

  reload(){
		var filters = [this.filter_search, this.paginator.pageIndex];
		this.isLoadingResults = true;
		this.services.getConfigurations({filters: filters, codigo_pais: this.country}).subscribe(data => {
			this.familias = new MatTableDataSource(data);
			this.services.getConfigurationsCount({filters: filters, codigo_pais: this.country}).subscribe(data => {
				this.familiasCount = data[0].total;
				this.isLoadingResults = false;
			});
		});
  }

  search() {
    this.filter_search = this.filter_search.trim();
    if(this.paginator.pageIndex > 0){
			this.paginator.firstPage();
		}
		this.reload();
	}

  insert(){
    const dialogRef = this.dialog.open(ingresoConfiguracion, {
      disableClose: true,
      width: '1000px',
      height: '90%',
      data: {}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.search();
    });
  }

  delete(configuracion){
    let dialogRef = this.dialog.open(deleteElement, {
      disableClose: true,
      data: {configuracion}
      });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
        this.services.deleteConfiguration(configuracion).subscribe(data => {
          this.search();
        });
      }
    });
  }

  requirements(configuracion){
    let dialogRef = this.dialog.open(verRequisitos, {
      disableClose: true,
      width: '400px',
      data: {configuracion}
    });
  }
}

@Component({
	selector: 'ingreso-configuracion',
  templateUrl: 'ingreso-configuracion.html',
  styles: ['.example-loading-shade {     position: absolute;     top: 0;     left: 0;     bottom: 56px;     right: 0;     background: rgba(0, 0, 0, 0.15);     z-index: 1;     display: flex;     align-items: center;     justify-content: center; } ::ng-deep .mat-sort-header-container {    display:flex;    justify-content:center;  }    th.mat-sort-header-sorted {    color: black;    text-align: center;  }  .mat-header-cell{	text-align: center;}.mat-cell{	text-align: center;}.tabledialog{	width: 100%;}table {    width: 100%;  }  .example-container {    position: relative;    background-color: white;  }    .example-table-container {    position: relative;  max-height: 268px;    overflow: auto;  }']
})

export class ingresoConfiguracion implements OnInit {

  isLoadingResults = false;
  requisitos;
  displayedColumns: string[] = ['no','requisito', 'obligatorio', 'del'];
  paises;
  familias;
  segmentos;
  scorings;
  productos;
  canales;
  familiasAux = [];
  segmentosAux = [];
  scoringsAux = [];
  productosAux = [];
  canalesAux = [];
  requisitosAux = [];
  filteredFamilias: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  filteredSegmentos: ReplaySubject<any[]> = new ReplaySubject<any[]>(1)
  filteredProductos: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  filteredCanales: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  filteredScorings: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  filteredRequisitos: ReplaySubject<any[]> = new ReplaySubject<any[]>(1);
  familiasSelected = false;
  segmentosSelected = false;
  scoringsSelected = false;
  productosSelected = false;
  canalesSelected = false;
  requisitosSelected = false;
  protected _onDestroy = new Subject<void>();
  requisitosConfiguraciones = new MatTableDataSource([]);

  pais = new FormControl('', Validators.required);

  formConfiguracion = new FormGroup({
    familias: new FormControl('', Validators.required),
    segmentos: new FormControl('', Validators.required),
    scorings: new FormControl('', Validators.required),
    productos: new FormControl('', Validators.required),
    canales: new FormControl('', Validators.required),
    filterFamilias: new FormControl('', []),
    filterSegmentos: new FormControl('', []),
    filterProductos: new FormControl('', []),
    filterCanales: new FormControl('', [])
  });
  
  formRequisitos = new FormGroup({
    requisitos: new FormControl(''),
    filterRequisitos: new FormControl('',[]),
    obligatorio: new FormControl(false)
  });

  constructor(private services: ServicesService, private dialogRef: MatDialogRef<ingresoConfiguracion>, @Inject(MAT_DIALOG_DATA) public data: any, private localSave: LocalSaveService, private snackBar: MatSnackBar, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.reload();
  }

  reload(){
    this.services.getCountries().subscribe(data =>{
      this.paises = data;
    });
  }

  updateByCountry(evt){
    this.isLoadingResults = true;
    this.services.getProductByCountry({codigo_pais: evt.value.codigo_pais}).subscribe(data =>{
      this.productos = data;
      this.productosAux = this.productos;
        this.filteredProductos.next(this.productos.slice());
        this.formConfiguracion.controls.filterProductos.valueChanges
          .pipe(takeUntil(this._onDestroy))
          .subscribe(() => {
            this.filterProductos();
          })
          this.services.getFamilyByCountry({codigo_pais: evt.value.codigo_pais}).subscribe(data =>{
            this.familias = data;
            this.familiasAux = this.familias;
            this.filteredFamilias.next(this.familias.slice());
            this.formConfiguracion.controls.filterFamilias.valueChanges
              .pipe(takeUntil(this._onDestroy))
              .subscribe(() => {
                this.filterFamilias();
              })
              this.services.getScoringByCountry({codigo_pais: evt.value.codigo_pais}).subscribe(data =>{
                this.scorings = data;
                this.scoringsAux = this.scorings;
                this.services.getSegmentByCountry({codigo_pais: evt.value.codigo_pais}).subscribe(data =>{
                  this.segmentos = data;
                  this.segmentosAux = this.segmentos;
                    this.filteredSegmentos.next(this.segmentos.slice());
                    this.formConfiguracion.controls.filterSegmentos.valueChanges
                      .pipe(takeUntil(this._onDestroy))
                      .subscribe(() => {
                        this.filterSegmentos();
                      })
                      this.services.getChannelByCountry({codigo_pais: evt.value.codigo_pais}).subscribe(data =>{
                        this.canales = data;
                        this.canalesAux = this.canales;
                        this.filteredCanales.next(this.canales.slice());
                        this.formConfiguracion.controls.filterCanales.valueChanges
                          .pipe(takeUntil(this._onDestroy))
                          .subscribe(() => {
                            this.filterCanales();
                          })
                          this.services.getRequirementByCountry({codigo_pais: evt.value.codigo_pais}).subscribe(data =>{
                            this.requisitos = data;
                            this.isLoadingResults = false;
                            this.requisitosAux = this.requisitos;
                            this.filteredRequisitos.next(this.requisitos.slice());
                            this.formRequisitos.controls.filterRequisitos.valueChanges
                              .pipe(takeUntil(this._onDestroy))
                              .subscribe(() => {
                                this.filterRequisitos();
                              })
                          });
                      });
                });
              });
          });
      });
  }

  save(){
      let dialogRef = this.dialog.open(resultadoInsercion, {
        disableClose: true,
        width:'100vw',
        data: {
          familias: this.formConfiguracion.value.familias,
          segmentos: this.formConfiguracion.value.segmentos,
          scorings: this.formConfiguracion.value.scorings,
          productos: this.formConfiguracion.value.productos,
          canales:  this.formConfiguracion.value.canales,
          pais: this.pais.value,
          requisitos: this.requisitosConfiguraciones.data,
          obligatorio: this.formRequisitos.value.obligatorio
        }
      });
      dialogRef.afterClosed().subscribe(result => {
        this.dialogRef.close();
      });
  }

  toggleProductos() {
    this.productosSelected = !this.productosSelected;
   
    this.filteredProductos.subscribe(el => {
      this.productosAux = el;
      this.filteredProductos.unsubscribe;
    }); 

    if (this.productosSelected) {
      this.formConfiguracion.controls.productos.setValue(this.productosAux);
    } else {
      this.formConfiguracion.controls.productos.setValue([]);
    }
  }

  filterProductos() {
    if (!this.productos) return;
    let search = this.formConfiguracion.controls.filterProductos.value;
    if (!search) {
      this.filteredProductos.next(this.productos.slice());
    } else {
      search = search.toLowerCase();
      this.filteredProductos.next(
        this.productos.filter(producto => producto.nombre.toLowerCase().indexOf(search) > -1)
      );
      this.productosAux = this.productos.filter(producto => producto.nombre.toLowerCase().indexOf(search) > -1)
    }
  }

  toggleFamilias() {
    this.familiasSelected = !this.familiasSelected;
   
    this.filteredFamilias.subscribe(el => {
      this.familiasAux = el;
      this.filteredFamilias.unsubscribe;
    }); 

    if (this.familiasSelected) {
      this.formConfiguracion.controls.familias.setValue(this.familiasAux);
    } else {
      this.formConfiguracion.controls.familias.setValue([]);
    }
  }

  filterFamilias() {
    if (!this.familias) return;
    let search = this.formConfiguracion.controls.filterFamilias.value;
    if (!search) {
      this.filteredFamilias.next(this.familias.slice());
    } else {
      search = search.toLowerCase();
      this.filteredFamilias.next(
        this.familias.filter(familia => familia.nombre.toLowerCase().indexOf(search) > -1)
      );
      this.familiasAux = this.familias.filter(familia => familia.nombre.toLowerCase().indexOf(search) > -1)
    }
  }

  toggleSegmentos() {
    this.segmentosSelected = !this.segmentosSelected;
  
    this.filteredSegmentos.subscribe(el => {
      this.segmentosAux = el;
      this.filteredSegmentos.unsubscribe;
    }); 
  
    if (this.segmentosSelected) {
      this.formConfiguracion.controls.segmentos.setValue(this.segmentosAux);
    } else {
      this.formConfiguracion.controls.segmentos.setValue([]);
    }
  }
  
  filterSegmentos() {
    if (!this.segmentos) return;
    let search = this.formConfiguracion.controls.filterSegmentos.value;
    if (!search) {
      this.filteredSegmentos.next(this.segmentos.slice());
    } else {
      search = search.toLowerCase();
      this.filteredSegmentos.next(
      this.segmentos.filter(segmento => segmento.nombre.toLowerCase().indexOf(search) > -1)
      );
      this.segmentosAux = this.segmentos.filter(segmento => segmento.nombre.toLowerCase().indexOf(search) > -1)
    }
  }

  toggleScorings() {
    this.scoringsSelected = !this.scoringsSelected;
    if (this.scoringsSelected) {
      this.formConfiguracion.controls.scorings.setValue(this.scoringsAux);
    } else {
      this.formConfiguracion.controls.scorings.setValue([]);
    }
  }
  

  toggleCanales() {
    this.canalesSelected = !this.canalesSelected;
  
    this.filteredCanales.subscribe(el => {
      this.canalesAux = el;
      this.filteredCanales.unsubscribe;
    }); 
  
    if (this.canalesSelected) {
      this.formConfiguracion.controls.canales.setValue(this.canalesAux);
    } else {
      this.formConfiguracion.controls.canales.setValue([]);
    }
  }
  
  filterCanales() {
    if (!this.canales) return;
    let search = this.formConfiguracion.controls.filterCanales.value;
    if (!search) {
      this.filteredCanales.next(this.canales.slice());
    } else {
      search = search.toLowerCase();
      this.filteredCanales.next(
      this.canales.filter(canal => canal.nombre.toLowerCase().indexOf(search) > -1)
      );
      this.canalesAux = this.canales.filter(canal => canal.nombre.toLowerCase().indexOf(search) > -1)
    }
  }

  toggleRequisitos() {
    this.requisitosSelected = !this.requisitosSelected;
  
    this.filteredRequisitos.subscribe(el => {
      this.requisitosAux = el;
      this.filteredRequisitos.unsubscribe;
    }); 
  
    if (this.requisitosSelected) {
      this.formRequisitos.controls.requisitos.setValue(this.requisitosAux);
    } else {
      this.formRequisitos.controls.requisitos.setValue([]);
    }
  }
  
  filterRequisitos() {
    if (!this.requisitos) return;
    let search = this.formRequisitos.controls.filterRequisitos.value;
    if (!search) {
      this.filteredRequisitos.next(this.requisitos.slice());
    } else {
      search = search.toLowerCase();
      this.filteredRequisitos.next(
        this.requisitos.filter(requisito => requisito.nombre.toLowerCase().indexOf(search) > -1)
      );
      this.requisitosAux = this.requisitos.filter(requisito => requisito.nombre.toLowerCase().indexOf(search) > -1)
    }
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  addRequirements(){
    for(let requirement of this.formRequisitos.value.requisitos){
      var r = {codigo_requisito: requirement.codigo_requisito, nombre: requirement.nombre, fuente: requirement.fuente, obligatorio: this.formRequisitos.value.obligatorio}
      this.requisitosConfiguraciones.data.push(r);
    }
    this.filteredRequisitos.next(
      this.requisitos.filter(x => this.checkRequirement(x))
    );
    this.formRequisitos.controls.requisitos.setValue([]);
    this.requisitosConfiguraciones = new MatTableDataSource(this.requisitosConfiguraciones.data);
  }

  removeRequirement(index){
    this.requisitosConfiguraciones.data.splice(index, 1);
    this.filteredRequisitos.next(
      this.requisitos.filter(x => this.checkRequirement(x))
    );
    this.formRequisitos.controls.requisitos.setValue([]);
		this.requisitosConfiguraciones = new MatTableDataSource(this.requisitosConfiguraciones.data);
  }

  checkRequirement(req): boolean{
    for(let r of this.requisitosConfiguraciones.data){
      if(req.codigo_requisito === r.codigo_requisito){
        return false;
      }
    }
    return true;
  }

  onNoClick(): void {
		this.dialogRef.close();
	}

  getErrorMessage(element){
		if(element.hasError('required')){
			return "Campo requerido";
		}else if(element.hasError('maxlength')){
			return "Longitud maxima de " + element.errors.maxlength.requiredLength + " caracteres";
		}
	} 

}

@Component({
	selector: 'ver-requisitos',
  templateUrl: 'ver-requisitos.html',
  styles: ['.example-loading-shade {     position: absolute;     top: 0;     left: 0;     bottom: 56px;     right: 0;     background: rgba(0, 0, 0, 0.15);     z-index: 1;     display: flex;     align-items: center;     justify-content: center; } ::ng-deep .mat-sort-header-container {    display:flex;    justify-content:center;  }    th.mat-sort-header-sorted {    color: black;    text-align: center;  }  .mat-header-cell{	text-align: center;}.mat-cell{	text-align: center;}.tabledialog{	width: 100%;}table {    width: 100%;  }  .example-container {    position: relative;    background-color: white;  }    .example-table-container {    position: relative;    max-height: 400px !important;    overflow: auto;  }']
})

export class verRequisitos  implements OnInit {
  
  configuracion;
  requisitos = new MatTableDataSource([]);
  displayedColumns: string[] = ['nombre', 'obligatorio'];
  isLoadingResults = false;

  constructor(private services: ServicesService, private dialogRef: MatDialogRef<verRequisitos>, @Inject(MAT_DIALOG_DATA) public data: any, private localSave: LocalSaveService, private dialog: MatDialog) {
    this.configuracion = this.data.configuracion;
  }

  ngOnInit(): void {
    this.isLoadingResults = true;
    this.services.getRequirementsConfiguration(this.configuracion).subscribe(data =>{
      this.requisitos = new MatTableDataSource(data);
      this.isLoadingResults = false;
    });
  }

  onNoClick(): void {
		this.dialogRef.close();
	}

}

@Component({
	selector: 'resultado-insercion',
  templateUrl: 'resultado-insercion.html',
  styles: ['.example-loading-shade {     position: absolute;     top: 0;     left: 0;     bottom: 56px;     right: 0;     background: rgba(0, 0, 0, 0.15);     z-index: 1;     display: flex;     align-items: center;     justify-content: center; } .thtable{text-align: center; justify-content: center;} .tdtable{text-align: center; justify-content: center;} .mat-cell{text-align: center; justify-content: center;} .mat-header-cell{text-align: center; justify-content: center;}']
})

export class resultadoInsercion{
  
  resultados = new MatTableDataSource([]);
  displayedColumns: string[] = ['pais', 'producto', 'canal', 'familia', 'scoring', 'segmento', 'requisito', 'obligatorio', 'resultado'];
  isLoadingResults = true;
  pais;
  familias;
  segmentos;
  scorings;
  productos;
  canales;
  requisitos;
  obligatorio;

  constructor(private services: ServicesService, private dialogRef: MatDialogRef<resultadoInsercion>, @Inject(MAT_DIALOG_DATA) public data: any, private localSave: LocalSaveService, private dialog: MatDialog) {
    this.familias = this.data.familias;
    this.segmentos = this.data.segmentos;
    this.scorings = this.data.scorings;
    this.productos = this.data.productos;
    this.canales = this.data.canales;
    this.requisitos = this.data.requisitos;
    this.pais = this.data.pais;
    this.obligatorio = this.data.obligatorio;
    this.save();
  }

  async save(){
    for(let familia of this.familias){
      for(let segmento of this.segmentos){
        for(let scoring of this.scorings){
          for(let producto of this.productos){
            for(let canal of this.canales){
              let configuracion = {familia: familia.codigo_familia, nombre_familia: familia.nombre, segmento: segmento.codigo_segmento, nombre_segmento: segmento.nombre, scoring: scoring.codigo_scoring, scoring_inferior: scoring.inferior, scoring_superior: scoring.superior, producto: producto.codigo_producto, nombre_producto: producto.nombre, canal: canal.codigo_canal, nombre_canal: canal.nombre, pais: this.pais.codigo_pais, nombre_pais: this.pais.nombre, requisito: "", obligatorio: "", resultado: ""};
              await this.insertConfiguration(configuracion);
            }
          }
        }
      }
    }
    this.isLoadingResults = false;
  }

  insertConfiguration(configuracion){
    return new Promise((resolve, reject) =>{
      this.services.insertConfiguration(configuracion).subscribe(async data =>{
        for(let requisito of this.requisitos){
          configuracion.requisito = requisito.nombre;
          configuracion.obligatorio = this.obligatorio
          await this.insertConfigurationRequirement(configuracion, data[0].resultado, requisito);
          resolve();
        }
      });
    });
  }

  insertConfigurationRequirement(configuracion, conf, requisito){
    return new Promise((resolve, reject) =>{
      this.services.insertConfigurationRequirement({configuracion: conf, requisito: requisito.codigo_requisito, obligatorio: this.obligatorio}).subscribe(data =>{
        if(data[0].resultado != -1){
          configuracion.resultado = "Ingresado exitosamente";
          this.resultados.data.push(configuracion);
          this.resultados = new MatTableDataSource(this.resultados.data);
        }else{
          configuracion.resultado = "Requisito ya existente";
          this.resultados.data.push(configuracion);
          this.resultados = new MatTableDataSource(this.resultados.data);
        }
        resolve();
      });
    });
  }

  onNoClick(): void {
		this.dialogRef.close();
	}

}

